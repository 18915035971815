import React, { useEffect, useState } from "react";
import "../Pages/MyProfile/MyProfile.css";
import { getEntireUniverseInfo, updateUniverse } from "../services/universe.service";
import { useSelector } from "react-redux";
import Sharepage from "./sharepeople/sharepage";
import userService from "../services/user.service";
import Switch from "./Toogle";


function UniversePageComponent({ item, className, is_public }) {
  const user = useSelector((state) => state.auth.user);
  const [universeCounts, setUniverseCounts] = useState({});
  const [is_open, setIs_open] = useState(false);
  const [universedata, setUniversedata] = useState({});
  const [userData, setuserData] = useState({});
  const [dbvalue, setdbValue] = useState(false);

  async function getUniverseInfo() {
    try {
      // console.log(item.id)
      const content = await getEntireUniverseInfo(
        item.id,
        user.accessToken
      );
      //  console.log(content.make_public)
      setUniverseCounts(content);
      setdbValue(content.make_public)
    } catch (e) {
      console.log(e);
    }
  }

  const togglePopup = async () => {
    setIs_open(false);
  }

  const getUser = async () => {
    console.log("inside")
    const userdata = await userService.getUsers();
    setuserData(userdata?.data?.user_list)
  }

  const changeToogle = async (value, universeCounts, setValue, user) => {
    // console.log(value.target.checked)
    try {
      setValue(value.target.checked)
      //   console.log(item.id,value)
      const content = await updateUniverse(
        { id: universeCounts.id, make_public: value.target.checked },
        user.accessToken
      );
      // console.log(content)
      //  return setValue(value.target.checked)
    } catch (e) {
      console.log(e);
    }
  }


  useEffect(() => {
    //  console.log("======>>>universepageComponent")
    getUniverseInfo();
    getUser();
  }, []);
  return (
    <>
      {is_open && <Sharepage style={{ "transition-delay": "5s" }}
        content={universedata}
        handleClose={togglePopup}
        universedata={universeCounts}
      />}
      {/* {console.log(universeCounts,universeCounts?.universe_title)} */}
      <div className={className}>
        <div className="MyUniverseBoxh6">
          <h6>{universeCounts?.universe_title}</h6>
        </div>
        <div className="d-flex col-12 MyUniverseBoxIcons">
          <div className="me-2 MyUniverseBoxSpan thumbsup">
            <span>
              <i className="fa fa-thumbs-up me-3 cursor" aria-hidden="true"></i>
              {universeCounts?.totalLikes}
            </span>
          </div>
          <div className="me-2 MyUniverseBoxSpan user">
            <span>
              <i className="fa fa-user me-3 cursor" aria-hidden="true"></i>
              {universeCounts?.totalContributors}
            </span>
          </div>
          <div className="MyUniverseBoxSpan random">
            <span>
              <i class="fa fa-random me-3 cursor" aria-hidden="true"></i>
              {universeCounts?.totalBranches}
            </span>
          </div>
        </div>
        <div className="d-flex col-12 MyUniverseBoxIcons">
          <div className="col-6">
            <p>{universeCounts?.universe_description}</p>
            <p>
              {!is_public && <button className="button" onClick={() => {
                setUniversedata({
                  universe_id: universeCounts?.id,
                  user_data: userData
                })
                setIs_open(true)
              }}>Share</button>}

            </p>
          </div>
          <div className="app col-6" style={{ padding: '5px' }}>
            <>
              {!is_public && (<>
                <div style={{ textAlign: "right" }}>
                <label style={{right: '10px'}} class="switch">
                  <input checked={dbvalue}  onChange={(e) => {
                      {
                        changeToogle(e, universeCounts, setdbValue, user)
                      }
                    }} type="checkbox" />
                    <span class="slider round"></span>
                </label>
               

                

                  {/* <label className="switch" for="checkbox" > */}
                  {/* <input
                    style={{ width: '15%', transform: "scale(2.5)" }}
                    checked={dbvalue}
                    value={universeCounts.id}
                    onChange={(e) => {
                      {
                        changeToogle(e, universeCounts, setdbValue, user)
                      }
                    }}
                    
                    type="checkbox"
                  />
                  {dbvalue ? (<div className="slidertog round"></div>) : (<div className="slidertogs round"></div>)}
                   */}
                  <label

                  // className="react-switch-label"
                  // htmlFor={`react-switch-new`}
                  >
                    PUBLIC IS  {dbvalue ? <span style={{
                      color: dbvalue ? '#06D6A0' : '#EF476F',
                    }}>ON</span> : <span style={{
                      color: dbvalue ? '#06D6A0' : '#EF476F',
                    }}>OFF</span>}
                  </label>
                </div>
              </>)}

            </>
          </div>
        </div>


      </div>
    </>
  );
}


export default UniversePageComponent;

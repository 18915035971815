import React, { useState, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "../Pages/MyProfile/MyProfile.css";
import { useSelector } from "react-redux";
import { updateUserCompanyInfo } from "../services/userinfo.service";
import { toast } from "react-toastify";
import profile_img from "../assets/images/myprofileimg.png";

function EditUserCompanyInfo({
  getUsetInfo,
  MenuPersonalClick,
  loading,
  setLoading,
  userdata,
  handleFileChange,
}) {
  const user = useSelector((state) => state.auth.user);
  const [userCompanyInfo, setUserCompanyInfo] = useState({
    user_id: user.id,
    company_name: userdata.company_name || "",
    company_location: userdata.company_location || "",
    company_website: userdata.company_website || "",
  });
  const fileInputRef = React.useRef();
  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };
  function updateCompanyInfoHandler(e) {
    const { name, value } = e.target;
    setUserCompanyInfo((prev) => ({ ...prev, [name]: value }));
  }
  async function updateUserCompanyInfoHandler(e) {
    e.preventDefault();
    setLoading(true);
    const response = await updateUserCompanyInfo(
      userCompanyInfo,
      user.accessToken
    );
    getUsetInfo();
    setLoading(false);
    MenuPersonalClick();
    toast.success("Profile updated!");
  }

  return (
    <div className="col-lg-9 col-12">
      <div className="col-12">
        <div className="col-12 d-flex align-items-center justify-content-between AddNewUniverseSection">
          <div className="d-flex align-items-center">
            <div className="Universesh6">
              <h6>Edit Profile</h6>
            </div>
          </div>
        </div>
        <div className="MyProfileadd">
          <Form onSubmit={(e) => updateUserCompanyInfoHandler(e)}>
            <div>
              <div className="col-12 d-flex MyProfileImgPart align">
                <div className="col-lg-3 col-4 position-relative d-flex align-items-center">
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <div
                    className="cursor MyProfileImg"
                    onClick={() => fileInputRef?.current?.click()}
                  >
                    <div>
                      <img
                        src={
                          userdata?.profile_path
                            ? `${process.env.REACT_APP_BASE_URL}/api/${userdata?.profile_path}`
                            : "/dummy.jpg"
                        }
                      />
                    </div>
                    <div className="MyProfileImgEdit position-absolute d-flex align-items-center justify-content-center">
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="logincontlabel">
              <label htmlFor="username">Company Name</label>
              <Input
                type="text"
                className="form-control"
                name="company_name"
                placeholder="Enter Company Name "
                value={userCompanyInfo.company_name}
                onChange={updateCompanyInfoHandler}
                validations={[required]}
              />
            </div>

            <div className="logincontlabel pwinput">
              <label htmlFor="password">Company Website</label>
              <Input
                type="description"
                className="form-control"
                name="company_website"
                placeholder="Enter company website"
                value={userCompanyInfo.company_website}
                onChange={updateCompanyInfoHandler}
                validations={[required]}
              />
            </div>

            <div className="logincontlabel pwinput">
              <label htmlFor="password">Company Location</label>
              <Input
                type="word_count"
                className="form-control"
                name="company_location"
                placeholder="Enter Company Location "
                value={userCompanyInfo.company_location}
                onChange={updateCompanyInfoHandler}
                validations={[required]}
              />
            </div>
            <div className="form-group buttonalign">
              <button className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Save</span>
              </button>

              <button
                className="btn btn-primary btn-block"
                disabled={loading}
                onClick={() => MenuPersonalClick()}
              >
                <span>Back to My Profile</span>
              </button>
            </div>
            <CheckButton style={{ display: "none" }} />
          </Form>
        </div>
      </div>
    </div>
  );
}

export default EditUserCompanyInfo;

import React, { useState, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "../Pages/MyProfile/MyProfile.css";
import { useSelector } from "react-redux";
import { updateUserInfo } from "../services/userinfo.service";
import { toast } from "react-toastify";
function EditProfileInfo({
  location,
  age,
  year_of_experience,
  getUsetInfo,
  onChangeAge,
  onChangeLocation,
  MenuPersonalClick,
  loading,
  onChangeYearofExperience,
  setLoading,
  onChangeAvailability,
  availability,
  onChangeName,
  nameValue
}) {
  console.log(year_of_experience,nameValue,age)
  const user = useSelector((state) => state.auth.user);
  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };
  async function saveUserProfileInfo(e) {
    e.preventDefault();
    setLoading(true);
    const response = await updateUserInfo(
      { user_id: user.id, age, location, year_of_experience,availability,name:nameValue },
      user.accessToken
    );
    getUsetInfo()
    setLoading(false);
    MenuPersonalClick()
    toast.success("Profile updated!")
  }
  return (
    <div className="col-lg-9 col-12">
      <div className="col-12">
        <div className="col-12 d-flex align-items-center justify-content-between AddNewUniverseSection">
          <div className="d-flex align-items-center">
            <div className="Universesh6">
              <h6>Edit Personal Information</h6>
            </div>
          </div>
        </div>
        <div className="MyProfileadd">
          <Form onSubmit={(e) => saveUserProfileInfo(e)}>
            <div className="logincontlabel">
              <label htmlFor="username">Age</label>
              <Input
                type="number"
                className="form-control"
                name="age"
                placeholder="Enter Age "
                value={age}
                onChange={onChangeAge}
                validations={[required]}
              />
            </div>
            <div className="logincontlabel">
              <label htmlFor="name">Name</label>
              <Input
                type="text"
                className="form-control"
                name="name"
                placeholder="Enter Name "
                value={nameValue}
                onChange={onChangeName}
                validations={[required]}
              />
            </div>
            <div className="logincontlabel">
              <label htmlFor="username">Availability</label>
              <Input
                type="text"
                className="form-control"
                name="availability"
                placeholder="Enter Availability "
                value={availability}
                onChange={onChangeAvailability}
                validations={[required]}
              />
            </div>
            <div className="logincontlabel pwinput">
              <label htmlFor="password">Location</label>
              <Input
                type="description"
                className="form-control"
                name="password"
                placeholder="Enter company website"
                value={location}
                onChange={onChangeLocation}
                validations={[required]}
              />
            </div>

            <div className="logincontlabel pwinput">
              <label htmlFor="password">Year Of Experience</label>
              <Input
                type="number"
                className="form-control"
                name="year_of_experience"
                placeholder="Enter Year Of Experience"
                value={year_of_experience}
                onChange={onChangeYearofExperience}
                validations={[required]}
              />
            </div>
            <div className="form-group buttonalign">
              <button className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Save</span>
              </button>

              <button
                className="btn btn-primary btn-block"
                disabled={loading}
                onClick={() => MenuPersonalClick()}
              >
                <span>Back to My Profile</span>
              </button>
            </div>
            {/* {message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {message}
                          </div>
                        </div>
                      )} */}
            <CheckButton style={{ display: "none" }} />
          </Form>
        </div>
      </div>
    </div>
  );
}

export default EditProfileInfo;

import React from 'react'
import "../Pages/GateKeeper/GateKeeper.css";
import Girl_img from "../assets/images/girl.png";
import { useSelector } from 'react-redux';
import { getSinglePostInfo } from '../services/universe.service';
import { useState } from 'react';
import { useNavigate } from 'react-router';
function GateKeeperCommonPost({postInfo}){
  const navigation = useNavigate();
  const user = useSelector(
    (state) => state.auth.user
  );
const [post,setPost] =useState({})
  

async function getPostDetails() {
  try {
    const response = await getSinglePostInfo(postInfo.id, user.accessToken);
    setPost(response);
  } catch (e) {
    console.log(e);
  }
}
React.useEffect(() => {
  getPostDetails();
}, []);
    return (
        <div onClick={() => navigation(`/HomeBrowse/posts-${post?.universe_id} > ${post?.breadCrumbs}`)} className="col-12 mb-2 d-flex flex-wrap GateKeeperBox mousepointer">
      <div className="col-lg-9 col-12" >
        <div className="d-flex align-items-center mb-md-2 mb-1">
          <div className="me-2 ContributionUniHead">
            
            <span>{post?.title}</span>
          </div>
        </div>
        <div className="mb-1 ContributionPara">
          <p>{post.content}</p>
        </div>
      </div>
      <div className="col-lg-3 col-12">
        <div className="col-12">
          <div className="d-flex justify-content-lg-end ContributionNumber">
            <span className="cursor">#{post?.id}</span>
          </div>
          <div className="d-flex justify-content-lg-end ContributionImagePart">
            <div className="col-12 me-1 d-flex ContributionImage">
              <span>
                <img src={Girl_img} className="me-2 cursor" />
                {post?.author?.username}
              </span>
            </div>
            <div className="me-1 ContributionGuarding">
              <span>
                <i className="fa fa-clock-o cursor" aria-hidden="true"></i>
                {postInfo?.universe?.owner_id === user.id?'Gatekeeping':'Guarding'}
              </span>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-lg-end GateKeeperIconsSection">
            <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
              <span>
                <i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>
                {post?.likes?.length}
              </span>
            </div>
            <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
              <span>
                <i className="fa fa-user cursor" aria-hidden="true"></i> {post?.contributorsCount?.length}
              </span>
            </div>
            <div className="GateKeeperBoxSpan GateKeeperrandom">
              <span>
                <i className="fa fa-random cursor" aria-hidden="true"></i>
                {post?.branchCount}
              </span>
            </div>
          </div>
          {/* <div className="col-12 d-flex justify-content-lg-end">
            <div className="me-1 GateKeeperTwoIcon">
              <i className="fa fa-heart cursor" aria-hidden="true"></i>
            </div>
            <div className="me-1 GateKeeperTwoIcon">
              <i className="fa fa-external-link cursor" aria-hidden="true"></i>
            </div>
            <div className="GateKeeperTwoIcon">
              <i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>
            </div>
          </div> */}
        </div>
      </div>
    </div>
    )
}

export default GateKeeperCommonPost

import styles from './sharepage.module.css';
// import styled from 'styled-components'
import PropTypes from 'prop-types'
// import universe from '../../services/universe.service';
import React, { Component } from 'react';
import { render } from 'react-dom';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { Form } from 'react-router-dom';


// import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';
import '../../../node_modules/react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { createInvitee,getRole } from '../../services/universe.service';
import { toast } from "react-toastify";
// import './styles.css';

const data = [
  { "user_id": 3, "universe_id": 3, "status": false, "role": "Alean" },
  { "user_id": 4, "universe_id": 3, "status": false, "role": "Alean" },
  { "user_id": 5, "universe_id": 3, "status": false, "role": "Master" },
  { "user_id": 6, "universe_id": 3, "status": false, "role": "Alean" }
]

class Sharepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: 'React',
      multiSelections: [],
      userdata: props.content.user_data,
      // userdata:[{email:'hari@tryangled.com'},{email:'hariram10121@gmail.com'},{email:'sam@gmail.com'}],
      slectedData: [],
      users: JSON.parse(localStorage.getItem("user")),
      nonmailer: null,
      role:[]
    };
    this.changeevt = this.changeevt.bind(this);
    this.nonemail = this.nonemail.bind(this);
    this.typeahead = React.createRef();
    this.getRoles()
    console.log(props, this.state.users)
  }

  _renderMenu(results, menuProps) {
    const items = results.map((result, index) => {
      if (result.divider === true) {
        return <li key={index} role="separator" className="divider" />;
      }
      if (result.header === true) {
        return <li key={index} className="dropdown-header">{result.email}</li>;
      }
      return (
        <MenuItem key={index} option={result.email}>
          <strong>{result.email}</strong>
        </MenuItem>
      );
    });
    return <Menu {...menuProps}>{items}</Menu>;
  }


  changeevt(e) {
    // console.log(e, this.state.userdata, this.state.nonmailer)
    // this.setState({
    //   slectedData: e
    // });
    const data = e.map(s => {
      // console.log(typeof s)
      if (typeof s == 'string') {
        return {
          universe_id: this.props.universedata.id,
          status: false,
          notification_show:true,
          role: 0,
          user_id: (this.state.userdata.find(v => v.email == s) ? this.state.userdata.find(v => v.email == s).id : 0),
          email: s,
          invitee_id:this.state.users.id,
          invitee_name:this.state.users.username,
          universe_title: this.props.universedata.universe_title
        }
      }else{
        return s
      }

    });
    // console.log(data)
    this.setState({
      slectedData: data
    })
    // console.log(this.state.slectedData)
  }

  async submitInvitation() {
    try{
      console.log(this.state.slectedData, this.state.nonmailer);
      const response =await createInvitee({data:this.state.slectedData},this.state.users.accessToken);
      console.log(response)
      toast.success("Invitation sent")
      this.props.handleClose()
    }catch(e){
      console.log(e);
    }
   
  }

  async getRoles(){
    try{
      const roles =await getRole();
      console.log(roles)
      this.state.role=roles.responce;
    } catch (e) {
      console.log(e);
    }
  
  }

  nonemail(value) {
    console.log(value.target.value, "===>>", value.key);
    if (value.key === ' ' || value.key === 'Spacebar') {
      // ' ' is standard, 'Spacebar' was used by IE9 and Firefox < 37
      
      const emaild = this.extractEmails(value.target.value);

      if (emaild.length > 0) {
        const data = emaild.map(s => {
          return {
            universe_id: this.props.universedata.id,
            status: false,
            notification_show:true,
            role: 0,
            user_id: 0,
            email: s,
            universe_title: this.props.universedata.universe_title
          }
        });

        this.setState({
          slectedData: [...this.state.slectedData, ...data]
        });
        console.log("inside")
        this.typeahead.current.clear();
        value.preventDefault();
      }

    }
  }

  extractEmails(str) {
    const emailRegex = /[\w\.-]+@[\w\.-]+\.\w+/g;
    return str.match(emailRegex);
  }
  

  render() {
    return (
      <div className={styles.tooltip}>
        <div className={styles.tooltiparrow}>
          <div>
            <div style={{ float: 'left',padding:'2px' }}>
              <h5>Share universe with people</h5>
            </div>
            <div style={{ cursor: 'pointer', fontSize: '20px' }} >
              <button style={{ paddingLeft: '211px' }} onClick={() => this.props.handleClose()}>X</button>
            </div>
          </div>
          <div>
            <Typeahead
              ref={this.typeahead}
              id="my-typeahead-id"
              selected={this.state.slectedData}
              options={this.state.userdata}
              multiple
              labelKey='email'
              onChange={this.changeevt}
              renderMenu={this._renderMenu}
              placeholder="Choose email..."
              onKeyDown={(e) => {
                this.nonemail(e)
              }}
            />
          </div>
          <div style={{ overflow: 'auto', height: '160px' }}>
            {console.log(this.state.slectedData)}
            {this.state.slectedData.map(val => {
              return (
                <div className={styles.inside}>
                  <div style={{ flex: 1, flexDirection: 'row', padding: '10px' }}>
                    <div style={{ flex: 1, alignItems: 'center', float: 'right', width: '30%' }} >
                      <select className="form-control" onChange={(e) => { val.role = Number(e.target.value) }} >
                      <option  value="0">Select</option>
                      {
                          this.state.role.map((x, y) =>
                            <option key={y} value={x.id}>{x.name}</option>)
                        }
                      </select>

                    </div>
                    <div style={{ flex: 1, alignItems: 'center',padding:'5px' }} >
                      <p>{val.email}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div>
            <button className='button' onClick={() => { this.submitInvitation() }}>Send</button>
          </div>
        </div>

      </div>
    );
  }
}

// render(<Sharepage/>, document.getElementById('root'));

export default Sharepage
import React from "react";
import "./PublicInformation.css";
import profile_img from "../../assets/images/myprofileimg.png";

class PublicInformation extends React.Component {
    render() {
        return (
            <>
                <div className="PublicInformationOverall">
                    <div className="col-12">
                        <div className="MyProfileRight">
                            <div className="PublicInformationSection">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="MyProfilePublicBlockh6">
                                        <h6>Public Information</h6>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-center cursor MyProfilePublicBlockIcon">
                                            <i className="fa fa-user-times" aria-hidden="true"></i>
                                        </div>
                                        <div className="MyProfilePublicBlockSpan">
                                            <span>Block or Report user</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 d-flex MyProfileImgPart">
                                    <div className="col-2 position-relative d-flex align-items-center PublicInformationImgSection">
                                        <div className="cursor PublicInformationImg">
                                            <div>
                                                <img src={profile_img} />
                                            </div>
                                            <div className="PublicInformationImgEdit position-absolute d-flex align-items-center justify-content-center">
                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="d-flex align-items-center justify-content-between MyProfileNameSection">
                                            <div className="d-flex align-items-center PublicInformationConth5">
                                                <h5>Jane Doe</h5>
                                            </div>
                                        </div>
                                        <div className="PublicInformationul">
                                            <ul>
                                                <li><a href="#" className="d-flex align-items-center"><i className="fa fa-building d-flex flex-wrap align-items-center justify-content-center" aria-hidden="true"></i> Name of the company</a></li>
                                                <li><a href="#" className="d-flex align-items-center"><i className="fa fa-map-marker d-flex align-items-center justify-content-center" aria-hidden="true"></i> Address</a></li>
                                                <li><a href="#" className="d-flex align-items-center"><i className="fa fa-link d-flex align-items-center justify-content-center" aria-hidden="true"></i> Website</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="MyUniverseSection">
                                <div className="MyUniversePrivate">
                                    <div className="MyUniverseh6">
                                        <h6>Private</h6>
                                    </div>
                                    <div>
                                        <span className="ListUniversesSpan">
                                            List Of Private Universes
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 d-flex TimeUpgradeSection">
                                    <div className="col-1 position-relative TimeUpgradeLogo">
                                        <i className="fa fa-bolt position-absolute" aria-hidden="true"></i>
                                    </div>
                                    <div>
                                        <div className="TimeUpgradeh5">
                                            <h5>Time to Upgrade</h5>
                                        </div>
                                        <div className="TimeUpgradeP">
                                            <p>
                                                Some message about upgrading to the paid package to have accesss to paid features as well as private universes.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="upgratenowspan">
                                    <span>Upgrade Now</span>
                                </div>
                            </div>
                            <div className="MyUniverseSection LastBoxBorder">
                                <div className="MyUniversePrivate">
                                    <div className="MyUniverseh6">
                                        <h6>Public</h6>
                                    </div>
                                    <div>
                                        <span className="ListUniversesSpan">
                                            List Of Public Universes
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-3 MyUniverseBox">
                                    <div className="MyUniverseBoxh6">
                                        <h6>Universe Title (a little more test to make it a realistic title)</h6>
                                    </div>
                                    <div className="d-flex col-12 MyUniverseBoxIcons">
                                        <div className="me-2 MyUniverseBoxSpan thumbsup">
                                            <span><i className="fa fa-thumbs-up me-3 cursor" aria-hidden="true"></i>2.8K</span>
                                        </div>
                                        <div className="me-2 MyUniverseBoxSpan user">
                                            <span><i className="fa fa-user me-3 cursor" aria-hidden="true"></i>8</span>
                                        </div>
                                        <div className="MyUniverseBoxSpan random">
                                            <span><i className="fa fa-random me-3 cursor" aria-hidden="true"></i>108</span>
                                        </div>
                                    </div>
                                    <div>
                                        <p>Description about the universe.some more text explaining the universe.so a lot to read about it.</p>
                                    </div>
                                </div>
                                <div className="MyUniverseBox">
                                    <div className="MyUniverseBoxh6">
                                        <h6>Universe Title (a little more test to make it a realistic title)</h6>
                                    </div>
                                    <div className="d-flex col-12 MyUniverseBoxIcons">
                                        <div className="me-2 MyUniverseBoxSpan Universethumbsup">
                                            <span><i className="fa fa-thumbs-up me-3 cursor" aria-hidden="true"></i>2.8K</span>
                                        </div>
                                        <div className="me-2 MyUniverseBoxSpan Universeuser">
                                            <span><i className="fa fa-user me-3 cursor" aria-hidden="true"></i>8</span>
                                        </div>
                                        <div className="MyUniverseBoxSpan Universerandom">
                                            <span><i className="fa fa-random me-3 cursor" aria-hidden="true"></i>108</span>
                                        </div>
                                    </div>
                                    <div>
                                        <p>Description about the universe.some more text explaining the universe.so a lot to read about it.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PublicInformation;


import { SELECT_UNIVERSE } from "../actions/types";

const initialState = {
    selectedUniverse:null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SELECT_UNIVERSE:
      return { selectedUniverse: payload };

    default:
      return state;
  }
}

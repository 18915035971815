import React, { useState, useEffect, useCallback } from "react";
import '../Payment/Payment.css';
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import Stripe from "../../components/Stripe/Stripe";
import { checkstatus, createIntent } from "../../services/universe.service";
import { useParams } from "react-router-dom";
// class UpgradePlan extends React.Component {
//     render() {
function PaymentPage() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [pricing, setPricing] = useState(10);
    const [checkDate, setCheckDate] = useState(0);
    // const [pricingToggle,setpricingToggle] = useState('');
    const [perDate, setPerDate] = useState('Monthly');
    const [showForm, setShowForm] = useState(false)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [subscription, setsubscription] = useState(false)
    const [clientSecret, setclientSecret] = useState('')
    const PrivateTab = () => {
        // if (localStorage.getItem("loginStatus") == 'LoggedIn') {
        //     // navigate('/PrivateTab');
        //     setShowForm(true)
        // } else {
        //     navigate('/login')
        // }
        setShowForm(true)
    }
    const HandlePricing = () => {
        if (checkDate == 1) {
            if (pricing == 10) {
                setPricing(120);
                setPerDate('yearly');
                setCheckDate(0)
            } else if (pricing == 120) {
                setPricing(10);
                setPerDate('Monthly');
                setCheckDate(0)
            }
        } else {
            setCheckDate(1)
        }

    };

   async function checksubsciption(){
        try{
            const response=await checkstatus({userid:user.id},user.accessToken);
            console.log(response)
            setsubscription(response.responce)
        }catch (e) {
            console.log(e);
          }
    }

    async function chckintent(){
        try{
            const response=await createIntent({userid:user.id},user.accessToken);
            console.log(response)
            setclientSecret(response.clientSecret)
        }catch (e) {
            console.log(e);
          }
    }
    useEffect(() => {
        checksubsciption();
    //    chckintent();
       console.log(id)
      }, []);
   

    return (
        <>
            <Header />
            <div className="UpgradePlanOverall">
                <div className="col-12">
                <div className="App align" style={{    border: "1px solid grey",
    padding:"50px"}}>
        <div>
            <span style={{fontSize: "xxx-large",color: 'grey'}}>{id}$</span>
        </div>
                                <Stripe setsubscription={setsubscription} setShowForm={setShowForm} clientSecret={clientSecret} />
                            </div>
                </div>
            </div>

        </>
    );
}
// }

export default PaymentPage;
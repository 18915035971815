import React from "react";
import "./ShareDocument.css";
import Share_Img from "../../assets/images/Headerimg.png";

class ShareDocument extends React.Component {
    render() {
        return (
            <>
                <div className="d-flex align-items-center justify-content-center ShareDocumentOverall">
                    <div className="ShareDocumentSection">
                        <div>
                            <div className="d-flex align-items-center justify-content-between ShareDocumentHeader">
                                <span className="ShareSpan">
                                    Share "Untitled document"
                                </span>
                                <div className="d-flex align-items-center justify-content-center ShareIcons">
                                    <div className="SharequestionIcon">
                                        <i className="fa fa-question-circle-o cursor" aria-hidden="true"></i>
                                    </div>
                                    <div>
                                        <i className="fa fa-cog cursor" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="ShareDocumentInput">
                                <input type="text" placeholder="Add people and groups" />
                            </div>
                            <div className="ShareDocumentImgSection">
                                <div className="ShareDocumentPeople">
                                    <span>People with access</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="col-1 cursor ShareDocumentImage">
                                        <img src={Share_Img} />
                                    </div>
                                    <div className="col d-flex align-items-center justify-content-between">
                                        <div>
                                            <div className="ShareDocumentName">
                                                <span>Vinay Samtani (you)</span>
                                            </div>
                                            <div className="cursor ShareDocumentMail">
                                                <span>info@vinaysamtani@gmail.com</span>
                                            </div>
                                        </div>
                                        <div className="ShareDocumentOwner">
                                            <span>Owner</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ShareDocumentImgSection">
                                <div className="ShareDocumentPeople">
                                    <span>People with access</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="col-1 cursor ShareDocumentlock">
                                        <i className="fa fa-lock d-flex align-items-center justify-content-center" aria-hidden="true"></i>
                                    </div>
                                    <div className="col d-flex align-items-center justify-content-between">
                                        <div>
                                            <div className="ShareDocumentName">
                                                <span>Restricted</span>
                                            </div>
                                            <div className="ShareDocumentMail">
                                                <span>Only people with access can open with the link</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <button className="d-flex align-items-center ShareDocumentCopy">
                                        <i className="fa fa-link" aria-hidden="true"></i>
                                        <span>Copy Link</span>
                                    </button>
                                </div>
                                <div>
                                    <button className="d-flex align-items-center ShareDocumentDone">
                                        <span>Done</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ShareDocument;
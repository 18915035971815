import React from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  ElementsConsumer,
  CardElement,
} from '@stripe/react-stripe-js';
import { createIntent, payment } from '../../services/universe.service';

class CheckoutForm extends React.Component {
  constructor(props){
    super(props)
    console.log(props)
    this.state={
      users: JSON.parse(localStorage.getItem("user")),
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const {stripe, elements} = this.props;

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      return;
    }
       const response = await payment(
        {
            user_id: this.state.users.id,
            is_paid: true,
            startAt: new Date(),
            endAt: this.setAfterOneMonth(),
            amount:Number(0.50)*100,
        },
        this.state.users.accessToken
    );
    console.log(response)

    try{
      const response=await createIntent({userid:this.state.users.id},this.state.users.accessToken);
      console.log(response)
      // setclientSecret(response.clientSecret)
  
    const clientSecret=response.clientSecret;
    // Create the PaymentIntent and obtain clientSecret
    // const res = await fetch('http://localhost:8080/api/create-intent',requestOptions);
    // console.log(res.json())
    // const {client_secret: clientSecret} = 'await res.json()';
    console.log(
      elements,
      clientSecret);
    const {error,paymentMethod} = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_FRONT_END}/upgradePlan`,
      },
    });

    if (error) {
      console.log(error)
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
    } else {
      console.log(paymentMethod)
    //   const response = await payment(
    //     {
    //         user_id: this.st.users.id,
    //         is_paid: true,
    //         startAt: new Date(),
    //         endAt: this.setAfterOneMonth(),
    //         amount:Number(0.50)*100,
    //     },
    //     user.accessToken
    // );
    // console.log(response)
    // window.location.href =  
    // "https://write.geeksforgeeks.org/"; 
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }catch (e) {
    console.log(e);
  }
  };

  setAfterOneMonth() {
    var currentDate = new Date();
    var afterOneMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
    return afterOneMonth;
}


  render() {
    const {stripe} = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <PaymentElement id='payment-element' />
        <button style={{ background: 'red', margin: '5px', borderRadius: '4px', color: 'white', padding: '5px', width: '100%' }} className="paymentclass" type="submit" disabled={!stripe}>
          Pay
        </button>
      </form>
    );
  }
}

const InjectedCheckoutForm = ({clientSecret}) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutForm stripe={stripe} elements={elements} clientSecret={clientSecret} />
    )}
  </ElementsConsumer>
);

const stripePromise = loadStripe('pk_test_51NxrKeAPaU1VCC1NQjePpyulYNDgUVY0tGUkU3cu4lGCK7YqNfdjafJOyPSduJZLhnsThRACJSaTddXd4bzMNiX200CQRyJPUs');

const options = {
  mode: 'payment',
  amount: Number(0.50)*100,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
 
  payment_method_types: [
    "card"
  ],
};

const Stripe = ({clientSecret}) => (
  <Elements stripe={stripePromise} options={options} >
    <InjectedCheckoutForm clientSecret={clientSecret} />
  </Elements>
);
export default Stripe
// ReactDOM.render(<Stripe />, document.body);
import React from "react";
import "../Pages/HomeBrowse/HomeBrowse.css";
import Girl_img from "../assets/images/girl.png";
import "./GateKeeper/GateKeeper.css";
import { useSelector } from "react-redux";
import Header from "./Header/Header";
import GateKeeperCommonPost from "../components/GateKeeperCommonPost";
import { useState } from "react";
import { getEntireUniverseInfo } from "../services/universe.service";
import { useEffect } from "react";
import { useNavigate } from "react-router";
function GateKeeperLikes() {
  const gateKeeperLikes = useSelector(
    (state) => state.auth.gateKeeperLikes
  );
  const navigate = useNavigate()
  const user = useSelector((state) => state.auth.user);
  const [universeCounts, setUniverseCounts] = useState({});
  async function getUniverseInfo() {
    try {
      const content = await getEntireUniverseInfo(
        gateKeeperLikes?.universe?.id,
        user.accessToken
      );
      setUniverseCounts(content);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
 
    getUniverseInfo();
  }, []);
  return (
    <>
    <Header />
    <div className="HomeBrowseOverall">
    <div className="GateKeeperContributionBoxSection">
    <div className="d-flex align-items-center justify-content-end m-2">
    <button  onClick={()=>{
          navigate(-1);
        }} className="ViewAllBtn">Go Back</button>
      
      </div>
      <div className="GateKeeperContributionBoxBorder">
        <div className="d-flex justify-content-center">
          <div className="GateKeeperContributionBox">
            <div className="d-flex flex-wrap mb-2">
              <div className="col-lg-7 col-12 mb-lg-0 mb-1 GateKeeperBoxh6">
                {console.log(gateKeeperLikes)}
                <h6>{universeCounts?.universe_title}</h6>
              </div>
              <div className="col-lg-5 col-12">
                <div className="d-flex right-align">
                  <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
                    <span>
                      <i
                        className="fa fa-thumbs-up cursor"
                        aria-hidden="true"
                      ></i>
                      {universeCounts?.totalLikes}
                    </span>
                  </div>
                  <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
                    <span>
                      <i className="fa fa-user cursor" aria-hidden="true"></i>{universeCounts?.totalContributors}
                    </span>
                  </div>
                  <div className="GateKeeperBoxSpan GateKeeperrandom">
                    <span>
                      <i className="fa fa-random cursor" aria-hidden="true"></i>
                      {universeCounts?.totalBranches}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="GateKeeperBoxPara">
              <p>{gateKeeperLikes.universe.universe_description}</p>
            </div>
          </div>
        </div>
        {gateKeeperLikes?.posts?.map((item) => {
          return <GateKeeperCommonPost postInfo={item} />;
        })}
      </div>
    </div>
    </div>
    </>
  );
}

export default GateKeeperLikes;

import React from "react";
import './ResetPW.css';
import login_image from '../../assets/images/login.png';
import { useNavigate } from 'react-router-dom';
import Login from "../Login/Login";

// class ResetPW extends React.Component {
//     render() {
    
function ResetPW() {
    const navigate = useNavigate();

const HomeCont=()=>{
    navigate('/');
}

const login=()=>{
    navigate('/login');
}
        return (
            <>
                <div className="overallwrapper">
                    <div className="d-flex flex-wrap loginInnerWrapper">
                        <div className="col-6 d-lg-flex d-none align-items-center justify-content-center position-relative loginleft">
                            <div className="loginEllipse">

                            </div>
                            <div className="position-absolute loginImg">
                                <img src={login_image} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="d-flex align-items-center justify-content-center logincont">
                                <div className="d-flex justify-content-center align-items-center tick">
                                    <span><i className="fa fa-check" aria-hidden="true"></i></span>
                                </div>
                                <div className="reseth5">
                                    <h5>Password Reset</h5>
                                </div>
                                <div className="text-center resetP">
                                    <p>Your password has been successfully reset.</p>
                                    <p>Click below to sign in magically.</p>
                                </div>
                                <div className="w-100">
                                    <button type="submit" className="continue" onClick={HomeCont} >Continue</button>
                                </div>
                                <div className="text-center loginRegister">
                                    <span>Back to</span>  <a href="#" className="register" onClick={ login} > Login!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
// }

export default ResetPW;
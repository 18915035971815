import React, { useEffect, useState } from "react";
import "../Pages/HomeBrowse/HomeBrowse.css";
import Branch from "../assets/images/Dropdown.png";
import { useDispatch, useSelector } from "react-redux";
import { FILTER_STATUS, SELECTED_TYPE, SINGLE_TIME } from "../redux/actions/types";
import Tooltip from "./popup/Tooltip";
import { getsubPosts, checkPermission } from "../services/universe.service";
import { usePagination, DOTS } from "./usePagination";

function Pagination({
  posts = [],
  setSelectedPost,
  setShowNewPostBox,
  setPostManagement,
  selectedPost,
  postManagement,
  level,
  universeInfo,
  parentId,

  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  setshowpost,
  readonly
}) {
  const dispatch = useDispatch();
  const singleState = useSelector(state => state.message.postStatus);
  const user = useSelector(state => state.auth.user);
  const [isOpen, setIsOpen] = useState(false);
  const [subpost, setsubpost] = useState([]);

  ////
  console.log({
    posts,
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
    selectedPost
  })
  const paginationRange = usePagination({
    posts,
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });
  console.log(paginationRange)
  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  if (selectedPost != null) {
    onPageChange(selectedPost.pageCount)
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  ////




  const togglePopup = async () => {
    setIsOpen(false);

    // if(isOpen == true){
    //   try {
    //     const content = await getsubPosts(
    //       {postId:postId,
    //         universeId:universeId},
    //       user.accessToken
    //     );
    //     console.log(content)
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

  }
  // console.log(singleState)
  const replaceSlash = (value) => {
    // console.log(value);
    if (value) {
      let splitData = value.split("\\").join('/');
      return splitData
    } else {
      return ''
    }

  }

  const getUniverseInfo = async (postId, universeId) => {
    try {
      const content = await getsubPosts(
        {
          postId: postId,
          universeId: universeId
        },
        user.accessToken
      );
      console.log(content);

    } catch (e) {
      console.log(e);
    }
  }

 

 

  const showFirstletter = (value) => {
    console.log(value);
    let finduserFirstletter = value.author?.username ? value.author?.username : value.username;
    let getFirstLetter = finduserFirstletter[0].toUpperCase();
    return getFirstLetter
  }

  return (


    <div
      className="d-flex align-items-center justify-content-center"
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      {isOpen && <Tooltip style={{ "transition-delay": "5s" }}
        content={subpost}
        handleClose={togglePopup}
      />}
      {/* {console.log(postManagement, "postManagement")} */}

      {/* {console.log(selectedPost, "selectedPost")} */}
      <nav aria-label="Page navigation example">
        <ul className="pagination HomeBrowsePagination">
          {paginationRange?.length
            ? paginationRange?.map((item, index) => (
              <>
                {item.id == DOTS ? <li className="dots"><svg xmlns="http://www.w3.org/2000/svg" height="3.5em" viewBox="0 0 448 512"><path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" /></svg></li> : <li
                  onMouseOver={async () => {
                    setIsOpen(true);

                    // if(isOpen == true){
                    try {
                      const content = await getsubPosts(
                        {
                          postId: item.id,
                          universeId: item.universe_id
                        },
                        user.accessToken
                      );
                      console.log(content);
                      setsubpost(content)
                    } catch (e) {
                      console.log(e);
                    }
                    // }
                  }}
                  onMouseLeave={togglePopup}
                  key={index + 89}
                  className="page-item"
                  onClick={() => {
                    setSelectedPost(item);
                    let findLevel = postManagement.findIndex(
                      (item) => item.level === level + 1
                    );
                    if (findLevel === -1) {
                      setPostManagement((prev) => [
                        ...prev,
                        {
                          level: level + 1,
                          posts: universeInfo?.posts.filter(
                            (post) => post.parentId === item.id
                          ),
                          parentId: item.id,
                        },
                      ]);
                    } else {
                      if (findLevel < postManagement.length - 1) {
                        const newArray = postManagement.slice(
                          0,
                          findLevel + 1
                        );
                        setPostManagement(newArray);
                      }
                      setPostManagement((prevArray) => {
                        const newArray = [...prevArray];
                        newArray[findLevel] = {
                          level: level + 1,
                          posts: universeInfo?.posts.filter(
                            (post) => post.parentId === item.id
                          ),
                          parentId: item.id,
                        };
                        return newArray;
                      });
                    }
                    dispatch({
                      type: SELECTED_TYPE,
                      payload: item,
                    });
                    dispatch({
                      type: FILTER_STATUS,
                      payload: false,
                    });
                    onPageChange(item.pageCount)
                    setshowpost(false)
                  }}
                >
                  <a
                    style={{ backgroundImage: 'url(' + `${process.env.REACT_APP_BASE_URL}/api/${replaceSlash(item?.author?.user_details[0].profile_path)}` + ')', backgroundSize: "cover" }}
                    className={`page-link ${selectedPost?.id === item.id
                      ? "selectedIndexColor"
                      : "nonSelectedIndexColor"
                      }`}
                  >
                    {/* {item.content} */}
                    {item?.author?.user_details[0].profile_path ? null : (<span style={{ fontSize: "24px", fontWeight: "500" }}>
                      {showFirstletter(item)}
                      {/* {index + 1} */}
                    </span>)}


                  </a>

                </li>}

              </>
            ))
            : null}
          <span
            onClick={() => {
              setShowNewPostBox((prev) => !prev);
              dispatch({
                type: SINGLE_TIME,
                payload: true,
              });
            }}
          >
            {(singleState == false && readonly == true) ? (<img src={Branch} className="me-2 cursor" />) : null}
          </span>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;

import React, { useState, useEffect } from "react";
import Pagination from "./Pagination";
import BranchPost from "./BranchPost";
import CreateNewPost from "./CreateNewPost";
import { useSelector } from "react-redux";
import { getsubPosts, checkPermission } from "../services/universe.service";

let PageSize = 10;

function Branch({
  item,
  setPostManagement,
  universeInfo,
  postManagement,
  setLoading,
  setUniverseInfo,
  setUpdateUniverseFlag,
  updateUniverseFlag,
  originalPostsLevel,
  setOriginalPostsLevel,
  setshowpost
}) {
  const [currentPage, setCurrentPage] = useState(1);


  const { level, posts, parentId, postToBeShown = null } = item;
  const [showNewPostBox, setShowNewPostBox] = useState(false);
  const [selectedPost, setSelectedPost] = useState(postToBeShown);
  const selectedValue = useSelector(state => state.subpost.selectedValue);
  const filterStatus = useSelector(state => state.filter.filterStatus);
  const user = useSelector(state => state.auth.user);
  const [readonly, setreadOnly] = useState(false);
  const [roledata, setroledata] = useState(1);
  const setvalue = (postManagement, item) => {
    console.log(filterStatus)
    if(filterStatus == false){
      if (selectedPost && item) {
     
        // console.log(postManagement, selectedPost, item)
        var indexdata = postManagement.findIndex(function (number) {
          return number.parentId == item.id;
        });
        if (indexdata != -1 && selectedPost) {
          // var findindex = postManagement.findIndex(function (number) {
          //   return number.parentId == selectedPost.id;
          // });

          var findindex = postManagement.findIndex(function (number) {
            if (selectedPost.parentId == 0) {
              return number.parentId == selectedPost.parentId
            } else {
              return number.parentId == selectedPost.id;
            }

          });
          // console.log(findindex)
          if (findindex != -1) {
            return selectedPost.id
          } else {
            return -23
          }
        }else{
         return selectedPost.id
        }
      }else{
        return selectedPost.id
      }
    }else{
      return selectedPost.id
    }
   
  }

  console.log(posts)
  const rearrangeArray = ()=>{
    if(posts.length > 0){
      const postarray=posts.map((s,index)=> {s.pageCount=(index+1);return s});
      console.log(postarray)
      return postarray
    }else{
      return []
    }
    
  }

  const createPostperssion = async () => {

    const response = await checkPermission(
      {
        "user_id": user.id,
        "email": user.email,
        "universe_id": universeInfo.id,
        "status": true
      },
      user.accessToken
    )
    setreadOnly(response.responce.status);
    setroledata(response.responce.id)
    console.log(response,user)
  }

  useEffect(() => {
    console.log(universeInfo)
    if(universeInfo.is_public == false && universeInfo.make_public == false){
      if( universeInfo.owner_id == user.id){
        console.log("yes")
        setreadOnly(true);
      }else{
        createPostperssion()
      }
    }else{
      setreadOnly(true);
    }
  }, []);
  return (
    <>
      <Pagination
        posts={rearrangeArray()}
        level={level}
        universeInfo={universeInfo}
        postManagement={postManagement}
        selectedPost={selectedPost}
        setSelectedPost={setSelectedPost}
        setShowNewPostBox={setShowNewPostBox}
        setPostManagement={setPostManagement}

        currentPage={currentPage}
        totalCount={posts.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
        setshowpost={setshowpost}
        readonly={readonly}
      />
      {console.log(posts,selectedPost)}
      {posts.length > 0 ? (
        !showNewPostBox && selectedPost ? (
          <BranchPost
            selectedPost={selectedPost.id == setvalue(postManagement, selectedValue) ? selectedPost.id : null}
            setUpdateUniverseFlag={setUpdateUniverseFlag}
            updateUniverseFlag={updateUniverseFlag}
            universeInfo={universeInfo}
          />
        ) : null
      ) : null}
      {showNewPostBox && (
        <CreateNewPost
          setShowNewPostBox={setShowNewPostBox}
          setSelectedPost={setSelectedPost}
          postManagement={postManagement}
          setPostManagement={setPostManagement}
          setLoading={setLoading}
          parent={parentId}
          wordLimit={universeInfo?.word_limit}
          item={item}
          universeInfo={universeInfo}
          level={level}
          setUniverseInfo={setUniverseInfo}
          setUpdateUniverseFlag={setUpdateUniverseFlag}
          roledata={roledata}
        />
      )}
    </>
  );
}

export default Branch;

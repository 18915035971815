import React from "react";
import HomeMale_img from "../assets/images/male.jpg";
import "../Pages/HomeBrowse/HomeBrowse.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
import {
  getContributors,
  getSinglePostInfo,
  likePostInUniverse,
  sharePost,
} from "../services/universe.service";
import Share from "../components/share/Share";
import { useNavigate } from "react-router";
import copy from 'copy-to-clipboard';

function BranchPost({
  selectedPost,
  setUpdateUniverseFlag,
  updateUniverseFlag,
  universeInfo
}) {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [postDetails, setPostDetails] = useState({});
  const [showShare, setshowShare] = useState(false);
  const [showUniversId, setUniversId] = useState(0);
  const [showUrl, setshowUrl] = useState("0");
  const navigation = useNavigate()
  async function likePost() {
    try {
      setLoading(true);
      const content = await likePostInUniverse(
        { postId: selectedPost, userId: user.id },
        user.accessToken
      );
      setLoading(false);
      setUpdateUniverseFlag((prev) => !prev);
      getPostDetails();
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }
  async function getPostDetails() {
    if (selectedPost) {
      try {
        const response = await getSinglePostInfo(
          selectedPost,
          user.accessToken
        );
        setPostDetails(response);

      } catch (e) {
        console.log(e);
      }
    }
  }
  const replaceSlash = (value) => {
    // console.log(value);
    if (value) {
      let splitData = value.split("\\").join('/');
      return splitData
    } else {
      return ''
    }

  }
  const showFirstletter = (value) => {
    console.log(value);
    let finduserFirstletter = value.author?.username ? value.author?.username : value.username;
    let getFirstLetter = finduserFirstletter[0].toUpperCase();
    return getFirstLetter
  }
  React.useEffect(() => {
    console.log(universeInfo)
    getPostDetails();
  }, [selectedPost, updateUniverseFlag]);
  return (
    <>
    
  
      {selectedPost && (
        <div className="HomeBrowsePaginationBoxSection">
          <div className="col-12 d-flex flex-wrap HomeBrowsePaginationBox">
            <div className="col-lg-9 col-12">
              <div className="col-12 HomeBrowseBoxUniHead">
                <span>{postDetails?.universe?.universe_title}</span>
                <span style={{ marginLeft: "2px" }}>
                  {postDetails?.breadCrumbs}
                </span>
              </div>
              <div className="mb-lg-0 mb-1 HomeBrowsePaginationBoxPara">
                <p>{postDetails?.content}</p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="col-12">
                <div className="d-flex justify-content-lg-end HomeBrowseNumber">
                  <span className="cursor">{`#${postDetails?.id}`}</span>
                </div>
                <div className="col-12 d-flex justify-content-lg-end HomeBrowseImageSection">
                  <div style={{cursor:"pointer"}} onClick={() => navigation(`/GateKeeper/${postDetails?.author?.id}`)} className="col-12 me-1 d-flex HomeBrowseImage">
                  {<span>
                    <img src={postDetails?.author?.user_details[0]?.profile_path?`${process.env.REACT_APP_BASE_URL}/api/${replaceSlash(postDetails?.author?.user_details[0]?.profile_path)}`:"/dummy.jpg"} className="me-2 cursor" />
                    {postDetails?.author?.username}
                  </span>}
                  </div>
                  {universeInfo?.is_public ? null:(<div className="col-12 d-flex HomeBrowseMaster">
                    <span>
                      <i className="fa fa-user cursor" aria-hidden="true"></i>
                      {postDetails?.role_name}
                    </span>
                  </div>)}
                  
                </div>
                <div className="col-12 d-flex justify-content-lg-end HomeBrowseIconsSection">
                  {loading ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : null}
                  <div className="me-1 HomeBrowseIconsSpan HomeBrowsethumbsup">
                    <span>
                      <i
                        className="fa fa-thumbs-up cursor"
                        aria-hidden="true"
                      ></i>
                      {postDetails?.likes?.length}
                    </span>
                  </div>
                  <div className="me-1 HomeBrowseIconsSpan HomeBrowseuser">
                    <span>
                      <i className="fa fa-user cursor" aria-hidden="true"></i>
                      {postDetails?.contributorsCount?.length}
                    </span>
                  </div>
                  <div className="HomeBrowseIconsSpan HomeBrowserandom">
                    <span>
                      <i className="fa fa-random cursor" aria-hidden="true"></i>
                      {postDetails?.branchCount}
                    </span>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-lg-end HomeBrowseIconsTwoSection">
                  {/* <div className="me-1 HomeBrowseIconsTwoIcon">
                <i className="fa fa-heart cursor" aria-hidden="true"></i>
              </div> */}
                  <div
                    className="me-1 HomeBrowseIconsTwoIcon"
                    onClick={async () => {
                      // disable();
                      console.log(selectedPost,postDetails?.universe?.id)
                      try {
                        let shareedpost = await sharePost(
                          {
                            postId: selectedPost,
                            universe_id: postDetails?.universe?.id,
                          },
                          user.accessToken
                        );
                        copy(
                          `${process.env.REACT_APP_FRONT_END}/HomeBrowse/${shareedpost?.token}`
                        );
                        setshowUrl(
                          `${process.env.REACT_APP_FRONT_END}/HomeBrowse/${shareedpost?.token}`
                        );
                        setshowShare((prevState) => !prevState);
                        setUniversId(postDetails?.universe?.id);
                        // toast.success("Link copied to clipboard");
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                    // onClick={async () => {
                    //   try {
                    //     let shareedpost = await sharePost(
                    //       {
                    //         postId: selectedPost,
                    //         universe_id: postDetails?.universe?.id,
                    //       },
                    //       user.accessToken
                    //     );
                    //     navigator.clipboard.writeText(
                    //       `http://localhost:3000/HomeBrowse/${shareedpost?.token}`
                    //     );

                    //     toast.success("Link copied to clipboard");
                    //   } catch (e) {
                    //     console.log(e);
                    //   }
                    // }}
                  >
                    <i
                      className="fa fa-external-link cursor"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="HomeBrowseIconsTwoIcon">
                    {!loading && (
                      <span onClick={() => likePost()}>
                        <i
                          className="fa fa-thumbs-up cursor"
                          style={
                            postDetails?.likes?.find(
                              (el) => el.userId == user.id
                            )
                              ? { color: "#9dc2ff" }
                              : {}
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

{showShare && (
        <Share
          setshowShare={setshowShare}
          showShare={showShare}
          selectedPost={selectedPost}
          showUniversId={showUniversId}
          user={user}
          showUrl={showUrl}
        ></Share>
      )}
    </>
  );
}

export default BranchPost;

import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FILTER_STATUS } from "../../redux/actions/types";
import { getOneUniverse } from "../../services/universe.service";

const Sidebar = ({
  setShowSideBar,
  universeInfo,
  setPostManagement,
  enable,
  originalPostsLevel,
  setLoading,
  selectedOption, setSelectedOption,
  setshowpost
}) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    setShowSideBar((prevState) => !prevState);
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  async function filterPostsWithWighestLikes() {
    setLoading(true);
    setPostManagement([]);
    setTimeout(async () => {
      let level = 0;
      let level2 = 1;
      const universe = await getOneUniverse(universeInfo.id, user.accessToken);
      let allPosts = universe.universe_data.posts;
      let foundPosts = allPosts.filter((item) => item.parentId === 0);
      if (foundPosts.length) {
        let objectWithHighestLikes = foundPosts.reduce(
          (max, obj) => (obj.likes.length > max.likes.length ? obj : max),
          foundPosts[0]
        );
        let postToBeShown = objectWithHighestLikes
          ? objectWithHighestLikes?.likes?.length
            ? objectWithHighestLikes
            : null
          : null;
        if (!postToBeShown) {
          objectWithHighestLikes = null;
        }
        let postManagementArrTemp = [
          {
            level: level,
            postToBeShown,
            posts: foundPosts,
            parentId: 0,
          },
        ];
        if (objectWithHighestLikes) {
          while (foundPosts.length && postToBeShown) {
            foundPosts = allPosts.filter(
              (item) => item.parentId === objectWithHighestLikes.id
            );
            objectWithHighestLikes = foundPosts.reduce(
              (max, obj) => (obj.likes.length > max.likes.length ? obj : max),
              foundPosts[0]
            );
            postToBeShown = objectWithHighestLikes
              ? objectWithHighestLikes?.likes?.length
                ? objectWithHighestLikes
                : null
              : null;

            postManagementArrTemp = [
              ...postManagementArrTemp,
              {
                level: level2++,
                postToBeShown: postToBeShown,
                posts: foundPosts,
                parentId: objectWithHighestLikes?.id,
              },
            ];
          }
        }
        setPostManagement(postManagementArrTemp);
      }
      setLoading(false);
    }, 1000);
    setshowpost(true)
  }
  async function filterPostsWithHighestContributions() {
    setLoading(true);
    setPostManagement([]);
    setTimeout(async () => {
      let level = 0;
      let level2 = 1;
      const universe = await getOneUniverse(universeInfo.id, user.accessToken);
      let allPosts = universe.universe_data.posts;
      let foundPosts = allPosts.filter((item) => item.parentId === 0);
      if (foundPosts.length) {
        let objectWithHighestLikes = foundPosts.reduce(
          (max, obj) => (obj.contributorsCount.length > max.contributorsCount.length ? obj : max),
          foundPosts[0]
        );
        let postToBeShown = objectWithHighestLikes
          ? objectWithHighestLikes?.contributorsCount?.length
            ? objectWithHighestLikes
            : null
          : null;
        if (!postToBeShown) {
          objectWithHighestLikes = null;
        }
        let postManagementArrTemp = [
          {
            level: level,
            postToBeShown,
            posts: foundPosts,
            parentId: 0,
          },
        ];
        if (objectWithHighestLikes) {
          while (foundPosts.length && postToBeShown) {
            foundPosts = allPosts.filter(
              (item) => item.parentId === objectWithHighestLikes.id
            );
            objectWithHighestLikes = foundPosts.reduce(
              (max, obj) => (obj.contributorsCount.length > max.contributorsCount.length ? obj : max),
              foundPosts[0]
            );
            postToBeShown = objectWithHighestLikes
              ? objectWithHighestLikes?.contributorsCount?.length
                ? objectWithHighestLikes
                : null
              : null;

            postManagementArrTemp = [
              ...postManagementArrTemp,
              {
                level: level2++,
                postToBeShown: postToBeShown,
                posts: foundPosts,
                parentId: objectWithHighestLikes?.id,
              },
            ];
          }
          setPostManagement(postManagementArrTemp);
        }
      }
      setLoading(false);
    }, 1000);
    setshowpost(true)
  }
  const optionArr = [
    "Most Liked Post",
    "Most Contributed Post",
    // "Most Split Post",
    // "Most Recently Updated Post",
  ];

  return (
    <div className={styles.sidebarParent}>
      <div className={styles.mainContentSplitter}>
        <div className={styles.sidebarMainBody}>
          <div className={styles.header}>
            <span className={styles.heading}>Filters</span>
            <button
              className={styles.closeBtn}
              onClick={() => {
                enable();
                toggleSidebar();
              }}
            >
              X
            </button>
          </div>
          <div className={styles.clearAllBtnWrapper}>
            <button
              className={styles.clearAllBtn}
              onClick={() => {
                setSelectedOption("");
                setPostManagement(originalPostsLevel);
              }}
            >
              Clear All
            </button>
          </div>
          <div className={styles.radioBtnsWrapper}>
            {optionArr.map((option) => {
              return (
                <div
                  className={`${styles.radioBtnCommon}  ${
                    selectedOption === option ? styles.radioBtnSelected : ""
                  }`}
                >
                  <label style={{ fontSize: "18px", cursor: "pointer" }}>
                    <input
                      className={styles.radioBtn}
                      type="radio"
                      value={option}
                      checked={selectedOption === option}
                      onChange={handleRadioChange}
                    />
                    {option}
                  </label>
                  <br />
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.btnsFooter}>
          <div>
            <button
              className={styles.cancelBtn}
              onClick={() => {
                enable();
                toggleSidebar();
              }}
            >
              Cancel
            </button>
            <button
              className={styles.applyBtn}
              onClick={() => {
                if (selectedOption === "Most Liked Post") {
                  filterPostsWithWighestLikes();
                }
                if (selectedOption === "Most Contributed Post") {
                  filterPostsWithHighestContributions();
                }
                enable();
                toggleSidebar();
                setSelectedOption(null)
                dispatch({
                  type: FILTER_STATUS,
                  payload: true,
                });
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

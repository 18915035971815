import React, { useState } from "react";
import "./Header.css";
import { Outlet, Link, NavLink, useNavigate } from "react-router-dom";
import Header_Img from "../../assets/images/Headerimg.png";
import { connect, useDispatch } from "react-redux";
import { login, logout } from "../../redux/actions/auth";
import { history } from "../../helpers/history";
import { getInvitee } from "../../services/universe.service";
import { useEffect } from "react";
import { MdNotificationsNone } from "react-icons/md";
import NotificaitonPopup from "../../components/notification/notification";

function Header() {
  const navigate = useNavigate();
  const [menus, setMenus] = useState([
    {
      id: 1,
      menuname: "Home",
      isActive: true,
      path: "/",
      css: "active Headercenterlibg",
    },
    { id: 2, menuname: "About Us", isActive: false, path: "/aboutus", css: "" },
    {
      id: 3,
      menuname: "Pricing",
      isActive: false,
      path: "/upgradePlan",
      css: "",
    },
    {
      id: 4,
      menuname: "Contact Us",
      isActive: false,
      path: "/contactus",
      css: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [active, setActive] = useState("");
  const [notificationData,setnotificationData] = useState([]);
  const [shownotification,setshowNotification] = useState(false);
  const dispatch = useDispatch();
  function handleClick(e) {
    setActive(setActive);
    for (var i = 0; i < menus.length; i++) {
      if (menus[i].id === e) {
        setActive(e);
      }
    }
  }

  async function getnotification(){
    try {
      console.log(user.id,user.accessToken)
    const response =await getInvitee({userid:user.id,email:user.email},user.accessToken);
    console.log(response)
    const dataSource=response.responce;
    setnotificationData(dataSource)
    } catch (e) {
      console.log(e);
    }
   
  }

  function showpopup(){
    setshowNotification(((prevState) => !prevState))
  }

  useEffect(() => {
    getnotification();
  }, []);

  return (
    <>
      <div className="d-flex floatleftcls Headeroverall">
        <nav className="navbar navbar-expand-lg navbar-light col-12">
          <div className="container-fluid">
            <div className="col-lg-3 col-12 position-relative d-flex align-items-center justify-content-between Headerleft">
              <div className="d-flex align-items-center">
                <div className="me-2 position-relative plluny">
                  <a className="navbar-brand position-absolute" href="#">
                    Y
                  </a>
                </div>
                <div>
                  <h6>Pllun</h6>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="align-items-center justify-content-center d-lg-none d-flex">
                  <NavLink className="Headerrighth6" to="/MyProfile">
                    <h6>{user?.username}</h6>
                  </NavLink>
                  <NavLink className="Headerrightimg" to="/MyProfile">
                    <img
                      src={
                        user?.profile_path
                          ? `${process.env.REACT_APP_BASE_URL}/api/${user?.profile_path}`
                          : "/dummy.jpg"
                      }
                    />
                  </NavLink>
                </div>
                <button
                  className="navbar-toggler align-items-center justify-content-center optionbar "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon d-flex justify-content-center align-items-center optionbaricon"></span>
                </button>
              </div>
            </div>
            <div
              className="collapse navbar-collapse col-9"
              id="navbarSupportedContent"
            >
              <div className="col-12 d-flex flex-wrap align-items-center">
                <div className="d-flex align-items-center justify-content-center pt-lg-0 pt-3 col-lg-8 col-12 Headercenter">
                  <div>
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      {menus.length
                        ? menus.map((item) => (
                          <>
                            <li className="nav-item ">
                              <NavLink
                                className={`nav-link ${item.id === active ? "active" : ""
                                  }`}
                                to={item.path}
                                onClick={() => {
                                  handleClick(item.id);
                                }}
                              >
                                {item.menuname}
                              </NavLink>
                            </li>
                          </>
                        ))
                        : null}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-12 pb-lg-0 pb-2">
                  <div className="d-flex align-items-center justify-content-lg-end justify-content-start Headerright">
                    <div className="align-items-center justify-content-center d-lg-flex d-none" style={{paddingRight: '15px'}}>
                      <button onClick={showpopup}>

                      <span >
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="25" fill="currentColor" class="bi bi-bell iconClass" viewBox="0 0 16 16">
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                      </svg> */}
                      <MdNotificationsNone style={{width:'25px',height:'30px'}} />
                      {notificationData.length > 0 ? (<span style={{background: 'red',
    padding: '5px',
    borderRadius: '0.25rem',}}>{notificationData.length}</span>):null}
                      
                      </span>
                      {/* <span class="badge badge-light dd">2</span> */}
                      </button>
                      {notificationData.length > 0 ?(shownotification && (<NotificaitonPopup notificationData={notificationData} setshowNotification={setshowNotification}></NotificaitonPopup>)):null}
                      
                      
                    </div>
                    <div className="align-items-center justify-content-center d-lg-flex d-none">
                      <NavLink
                        className="Headerrighth6"
                        onClick={() => {
                          dispatch(logout());
                          navigate("/login");
                        }}
                      >
                        <h6>Logout</h6>
                      </NavLink>
                      <NavLink className="Headerrighth6" to="/MyProfile">
                        <h6>{user?.username}</h6>
                      </NavLink>
                      <NavLink className="Headerrightimg" to="/MyProfile">
                        <img src={
                          user?.profile_path
                            ? `${process.env.REACT_APP_BASE_URL}/api/${user?.profile_path}`
                            : "/dummy.jpg"
                        } />
                      </NavLink>
                    </div>
                    {user?.is_paid == 0 && (
                      <NavLink to="/UpgradePlan" className="upgratespan">
                        <span>Upgrade</span>
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Header;

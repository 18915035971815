import React, { useState, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "../Pages/MyProfile/MyProfile.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateUserBillingInfo } from "../services/userinfo.service";
import {isEmail} from "validator";
function EditBilling({
  MenuPersonalClick,
  loading,
  onChangeBillingContact,
  billing_contact,
  onChangeBillingAddress,
  billing_address_1,
  onChangeBillingEmail,
  billing_email,
  editProfile,
  billing_name,
  onChangeBillingName,
  setLoading,
  getUsetInfo
}) {
  const user = useSelector((state) => state.auth.user);

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const email = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid email.
        </div>
      );
    }
  };

    const vphone = (value) => {
      console.log(value)
    if (value.length < 10 || value.length > 10) {
      console.log("inside")
      return (
        <div className="alert alert-danger" role="alert">
            Phone Number must be 10 digit
        </div>
      );
    }
  };

  async function saveUserBillingInfo(e) {
    e.preventDefault();
    console.log("INFO",{ user_id: user.id, billing_name, billing_email, billing_contact,billing_address_1 })
    setLoading(true);
    const response = await updateUserBillingInfo(
      { user_id: user.id, billing_name, billing_email, billing_contact,billing_address_1 },
      user.accessToken
    );
    getUsetInfo()
    setLoading(false);
    MenuPersonalClick()
    toast.success("Profile updated!")
  }
  return (
    <div className="col-lg-9 col-12">
      <div className="col-12">
        <div className="col-12 d-flex align-items-center justify-content-between AddNewUniverseSection">
          <div className="d-flex align-items-center">
            <div className="Universesh6">
              <h6>Edit Billing Information</h6>
            </div>
          </div>
        </div>
        <div className="MyProfileadd">
          <Form onSubmit={(e) => saveUserBillingInfo(e)}>
            <div className="logincontlabel">
              <label htmlFor="username">Billing Name</label>
              <Input
                type="text"
                className="form-control"
                name="age"
                placeholder="Enter Billing Name "
                value={billing_name}
                onChange={onChangeBillingName}
                validations={[required]}
              />
            </div>

            <div className="logincontlabel pwinput">
              <label htmlFor="password">Billing Email</label>
              <Input
                type="description"
                className="form-control"
                name="password"
                placeholder="Enter Billing Email"
                value={billing_email}
                onChange={onChangeBillingEmail}
                validations={[required,email]}
              />
            </div>

            <div className="logincontlabel pwinput">
              <label htmlFor="password">Billing Address</label>
              <Input
                type="year_of_experience"
                className="form-control"
                name="year_of_experience"
                placeholder="Enter Billing Address"
                value={billing_address_1}
                onChange={onChangeBillingAddress}
                validations={[required]}
              />
            </div>

            <div className="logincontlabel pwinput">
              <label htmlFor="password">Billing Contact</label>
              <Input
                type="number"
                className="form-control"
                name="contact"
                placeholder="Enter Contact"
                value={billing_contact}
                onChange={onChangeBillingContact}
                validations={[required,vphone]}
              />
            </div>
            <div className="form-group buttonalign">
              <button className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Save</span>
              </button>

              <button
                className="btn btn-primary btn-block"
                disabled={loading}
                onClick={() => MenuPersonalClick()}
              >
                {loading && <span className="spinner-border"></span>}

                <span>Back to My Profile</span>
              </button>
            </div>
            {/* {message && (
                                    <div className="form-group">
                                        <div className="alert alert-danger" role="alert">
                                        {message}
                                        </div>
                                    </div>
                                    )} */}
            <CheckButton style={{ display: "none" }} />
          </Form>
        </div>
      </div>
    </div>
  );
}

export default EditBilling;

import React, { useState } from "react";
import styles from './notification.module.css';
import { updateInvitee } from "../../services/universe.service";
import { toast } from "react-toastify";

async function updateinvitee(id,status,accessToken){
    console.log(id,accessToken)
    try{
        const responce = await updateInvitee({id:id,status:status},accessToken);
        console.log(responce);
        toast.success("Invitaion Accepted")
        refreshPage()
    } catch (e) {
      console.log(e);
    }
}

function refreshPage() {
    window.location.reload(false);
  }

const NotificaitonPopup = ({ notificationData, setshowNotification }) => {
  const [show, setShow] = React.useState(false);
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("user")));
    console.log(notificationData,user)
  return (

    <>

      <div className={styles.tooltip}>
        {/* {console.log(content)} */}
        <div className={styles.tooltiparrow}>
        {notificationData.map(val=>{
            return (
                
                <div style={{border: "1px solid grey",borderRadius: "10px"}}>
                <div style={{ flex: 1, flexDirection: 'row',padding: '10px' }}>
                  <div style={{ flex: 1, alignItems: 'center', }} >
                  <div style={{ flex: 1, alignItems: 'center' ,padding: '5px'}} >
                    <p style={{fontSize:'larger'}}>{val.universe_title}</p>
                    <span style={{color:'grey'}}>@{val.invitee_name}</span>
                  </div>
                   <button className={styles.buttonval} onClick={(e)=>{e.preventDefault();val.status=true;updateinvitee(val.id,true,user.accessToken);setshowNotification(false)}}>Accept</button>
                   <button className={styles.buttonvalreject} onClick={(e)=>{e.preventDefault();val.status=true;updateinvitee(val.id,false,user.accessToken);setshowNotification(false)}}>Reject</button>
                  </div>
                  
                </div>
              </div>
            )
        })}
       
        </div>
      </div>



    </>



    // <div>
    //   <div className="tooltip" style={show ? { visibility: "visible" } : {}}>
    //     {text}
    //     <span className="tooltip-arrow" />
    //   </div>
    //   <div
    //     {...rest}
    //     onMouseEnter={() => setShow(true)}
    //     onMouseLeave={() => setShow(false)}
    //   >
    //     {children}
    //   </div>
    // </div>
  );
}

export default NotificaitonPopup
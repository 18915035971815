import React, { useState } from "react";
import styles from "./Share.module.css";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";
import {
  FacebookShareCount,
  HatenaShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount
} from "react-share";
import copy from 'copy-to-clipboard';
import { sharePost } from "../../services/universe.service";
const shareurl = `${process.env.REACT_APP_FRONT_END}/HomeBrowse`;
const Share = ({
  setshowShare,
  showShare,
  selectedPost,
  showUniversId,
  user,
  showUrl
}) => {
  console.log(showUrl)
  const [shareUrl, setshareUrl] = useState('');
  // setshareUrl('http://localhost:3000/HomeBrowse');

  function test() {
    setshareUrl(`${process.env.REACT_APP_FRONT_END}/HomeBrowse`);
    return shareUrl
  }

  const toggleSidebar = () => {
    setshowShare((prevState) => !prevState);
  };

 const inputChangedHandler = (event) => {
    const updatedKeyword = event.target.value;
    // May be call for search result
}

  return (
    <div className={styles.sidebarParent}>
      <div className={styles.mainContentSplitter}>
        <div className={styles.sidebarMainBody}>
          <div className={styles.header}>
            <span className={styles.heading}>Share</span>
            <button
              className={styles.closeBtn}
              onClick={() => {
                // enable();
                toggleSidebar();
              }}
            >
              X
            </button>
          </div>

          <div>
            
            <div className={styles.container}>
              <i  className="url-icon uil uil-link"></i>
              <input className={styles.containerinput} type="text"  onChange={(event)=> inputChangedHandler(event)} value={showUrl} />
                <button className={styles.containerbutton} 
                
                onClick={async () => {
                  try {
                    let shareedpost = await sharePost(
                      {
                        postId: selectedPost,
                        universe_id: showUniversId,
                      },
                      user.accessToken
                    );
                    copy(
                      `${process.env.REACT_APP_FRONT_END}/HomeBrowse/${shareedpost?.token}`
                    );

                    toast.success("Link copied to clipboard");
                  } catch (e) {
                    console.log(e);
                  }
                }}
                >Copy</button>
            </div>
          </div>
              <div className={styles.center}>
              <p>Or</p>
              </div>
          <div className={styles.radioBtnsWrapper}>
            <div className={styles.align}>
              <div className={styles.alignicons}>
                <FacebookShareButton url={showUrl}>
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton>
              </div>
              <div className={styles.alignicons}>
                <WhatsappShareButton url={showUrl}>
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
              </div>
              <div className={styles.alignicons}>
                <EmailShareButton url={showUrl}>
                  <EmailIcon size={40} round={true} />
                </EmailShareButton>
              </div>
              <div className={styles.alignicons}>
                <LinkedinShareButton url={showUrl}>
                  <LinkedinIcon size={40} round={true} />
                </LinkedinShareButton>
              </div>
              <div className={styles.alignicons}>
                <TwitterShareButton url={showUrl}>
                  <TwitterIcon size={40} round={true} />
                </TwitterShareButton>
              </div>
              <div className={styles.alignicons}>
                <TelegramShareButton url={showUrl}>
                  <TelegramIcon size={40} round={true} />
                </TelegramShareButton>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div >
  );
};

export default Share;

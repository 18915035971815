import React from "react";
import "./MyProfilePublicBlock.css";
import profile_img from "../../assets/images/myprofileimg.png";
import payment_img from "../../assets/images/paypal.png";

class MyProfilePublicBlock extends React.Component {
    render() {
        return (
            <>
                <div className="MyProfilePublicBlockOverAll">
                    <div className="col-12 d-flex flex-wrap">
                        <div className="col MyProfileLeft">
                            <div className="accordion MyProfileAccordionoverall" id="accordionExample">
                                <div className="accordion-item MyProfileAccordion">
                                    <h2 className="accordion-header  MyProfileAccordionh2" id="headingOne">
                                        <button className="accordion-button position-relative col-12 MyProfileAccordionbutton" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <div className="d-flex align-items-center justify-content-between col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="MyProfileDownArrow">
                                                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                                                    </div>
                                                    <div>
                                                        <span>My Profile</span>
                                                    </div>
                                                </div>
                                                <div className="MyProfileA">
                                                    <a href="#">32</a>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body MyProfileAccordionUL">
                                            <ul>
                                                <li className="MyProfileAccordionULbg"><a href="#">Public Information</a></li>
                                                <li><a href="#">Personal Information</a></li>
                                                <li><a href="#">Billing Information</a></li>
                                                <li><a href="#">Payment History</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between MyProfileSpan">
                                    <div>
                                        <span>My Universes</span>
                                    </div>
                                    <div className="MyProfileA MyProfileAbg">
                                        <a href="#">32</a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between MyProfileSpan">
                                    <div>
                                        <div><span>My Contributions</span></div>
                                    </div>
                                    <div className="MyProfileA MyProfileAbg">
                                        <a href="#">256</a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between MyProfileSpan">
                                    <div>
                                        <div><span>My Likes</span></div>
                                    </div>
                                    <div className="MyProfileA MyProfileAbg">
                                        <a href="#">12k</a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between MyProfileSpan MyProfileSpanLast">
                                    <div>
                                        <div><span>My Plans</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-12">
                            <div className="col-12">
                                <div className="MyProfileRight">
                                    <div className="MyProfilePictureSection">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="MyProfilePublicBlockh6">
                                                <h6>Public Information</h6>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center justify-content-center cursor MyProfilePublicBlockIcon">
                                                    <i className="fa fa-user-times" aria-hidden="true"></i>
                                                </div>
                                                <div className="MyProfilePublicBlockSpan">
                                                    <span>Block or Report user</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex MyProfileImgPart">
                                            <div className="col-lg-3 col-4 position-relative d-flex align-items-center">
                                                <div className="cursor MyProfileImg">
                                                    <div>
                                                        <img src={profile_img} />
                                                    </div>
                                                    <div className="MyProfileImgEdit position-absolute d-flex align-items-center justify-content-center">
                                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="col-12 d-flex align-items-center justify-content-between MyProfileNameSection">
                                                    <div className="d-flex align-items-center MyProfileConth5">
                                                        <h5>Jane Doe</h5>
                                                    </div>
                                                </div>
                                                <div className="MyProfileContul">
                                                    <ul>
                                                        <li><a href="#" className="d-flex align-items-center"><i className="fa fa-building d-flex flex-wrap align-items-center justify-content-center" aria-hidden="true"></i> Name of the company</a></li>
                                                        <li><a href="#" className="d-flex align-items-center"><i className="fa fa-map-marker d-flex align-items-center justify-content-center" aria-hidden="true"></i> Address</a></li>
                                                        <li><a href="#" className="d-flex align-items-center"><i className="fa fa-link d-flex align-items-center justify-content-center" aria-hidden="true"></i> Website</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="MyProfilePictureSection">
                                        <div className="d-flex align-items-center justify-content-between MyProfileh6div">
                                            <div className="MyProfileh6">
                                                <h6>Personal Information</h6>
                                            </div>
                                            <div className="MyProfileContbutton">
                                                <button className="d-flex align-items-center justify-content-between"><i className="fa fa-pencil" aria-hidden="true"></i> <span> Edit</span></button>
                                            </div>
                                        </div>
                                        <div className="MyProfileInformation">
                                            <ul>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-5">Name:</div>
                                                    <div className="col-md-8 col-7">Jane Doe</div>
                                                </li>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-5">Email:</div>
                                                    <div className="col-md-8 col-7">info@vinaysamtani@gmail.com</div>
                                                </li>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-5">Availability:</div>
                                                    <div className="col-md-8 col-7">Full Time (UI/UX Designer)</div>
                                                </li>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-5">Age:</div>
                                                    <div className="col-md-8 col-7">29</div>
                                                </li>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-5">Location:</div>
                                                    <div className="col-md-8 col-7">Location</div>
                                                </li>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-5">Year Experience:</div>
                                                    <div className="col-md-8 col-7">7 Year Experience</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="MyProfilePictureSection">
                                        <div className="d-flex align-items-center justify-content-between MyProfileh6div">
                                            <div className="MyProfileh6">
                                                <h6>Billing Information</h6>
                                            </div>
                                            <div className="MyProfileContbutton">
                                                <button className="d-flex align-items-center justify-content-between"><i className="fa fa-pencil" aria-hidden="true"></i> <span> Edit</span></button>
                                            </div>
                                        </div>
                                        <div className="MyProfileInformation">
                                            <ul>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-5">Name:</div>
                                                    <div className="col-md-8 col-7">Jane Doe</div>
                                                </li>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-5">Email:</div>
                                                    <div className="col-md-8 col-7">info@Jane Doe@gmail.com</div>
                                                </li>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-5">Address:</div>
                                                    <div className="col-md-8 col-7">Address</div>
                                                </li>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-5">Contact:</div>
                                                    <div className="col-md-8 col-7">+91 9876543210</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="MyProfilePictureSection LastBoxBorder">
                                        <div className="d-flex align-items-center justify-content-between MyProfileh6div">
                                            <div className="MyProfileh6">
                                                <h6>Payment History</h6>
                                            </div>
                                            <div className="MyProfileContbutton">
                                                <button><i className="fa fa-pencil" aria-hidden="true"></i><span>Edit</span></button>
                                            </div>
                                        </div>
                                        <div className="MyProfileInformation cardpart">
                                            <ul>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-6">Payment Amount:</div>
                                                    <div className="col-md-8 col-6 PaymentAmount">$20</div>
                                                </li>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-6">Payment Method:</div>
                                                    <div className="col-md-8 col-6">
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex align-items-center MyProfilecard">
                                                                <i class="fa fa-credit-card" aria-hidden="true"></i>
                                                            </div>
                                                            <div className="MyProfileInformationimg">
                                                                <img src={payment_img} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="d-flex">
                                                    <div className="col-md-4 col-6">Payment Transaction Id:</div>
                                                    <div className="col-md-8 col-6">txn_123456789</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MyProfilePublicBlock;

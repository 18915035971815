import React from "react";
import './ContactUs.css';
import HomeMale_img from "../../assets/images/male.jpg";
import Girl_img from "../../assets/images/girl.png";
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
// class HomeBrowse extends React.Component {

function ContactUs() {
    const navigate = useNavigate();

const GateKeeper=()=>{
    //navigate('/GateKeeper');
}
    // render() {
        return (
            <>
            <Header/>
            <div className="homecontoverall">
                    <div className="py-3  justify-content-between choose">
                    <div className="col-6 d-flex align-items-center">
                            <h4>Contact Us</h4>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <h5>Coming Soon...</h5>
                        </div>
                        
                    </div>
            </div>
            </>
        );
    }
// }

export default ContactUs;



import React, { useState, useEffect, useCallback } from "react";
import "./UpgradePlan.css";
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import Stripe from "../../components/Stripe/Stripe";
import { checkstatus } from "../../services/universe.service";

// class UpgradePlan extends React.Component {
//     render() {
function UpgradePlan() {
    const navigate = useNavigate();
    const [pricing, setPricing] = useState(10);
    const [checkDate, setCheckDate] = useState(0);
    // const [pricingToggle,setpricingToggle] = useState('');
    const [perDate, setPerDate] = useState('Monthly');
    const [showForm, setShowForm] = useState(false)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [subscription, setsubscription] = useState(false)
    const PrivateTab = () => {
        // if (localStorage.getItem("loginStatus") == 'LoggedIn') {
        //     // navigate('/PrivateTab');
        //     setShowForm(true)
        // } else {
        //     navigate('/login')
        // }

        navigate(`/PaymentPage/${pricing}`)
        // setShowForm(true)
    }
    const HandlePricing = () => {
        if (checkDate == 1) {
            if (pricing == 10) {
                setPricing(120);
                setPerDate('yearly');
                setCheckDate(0)
            } else if (pricing == 120) {
                setPricing(10);
                setPerDate('Monthly');
                setCheckDate(0)
            }
        } else {
            setCheckDate(1)
        }

    };

   async function checksubsciption(){
        try{
            const response=await checkstatus({userid:user.id},user.accessToken);
            console.log(response)
            setsubscription(response.responce)
        }catch (e) {
            console.log(e);
          }
    }
    useEffect(() => {
        checksubsciption();
      }, []);
    // const HandlePricing = useCallback(()=>{
    //     console.log("date",pricing)
    //     if(pricing == 10){
    //         console.log("date1")
    //         setPricing(120) ;
    //         setPerDate('yearly') ; 
    //     }else if(pricing==120){
    //         setPricing(10) ;
    //         setPerDate('Monthly') ; 
    //     }
    //     else{
    //         console.log("date2",pricing)
    //         if(pricing==10){
    //             setPricing(120) ;
    //             setPerDate('Yearly');             
    //         }else{
    //             setPricing(10) ;
    //             setPerDate('Monthly');             
    //         }


    //     }    
    // });

    return (
        <>
            <Header />
            <div className="UpgradePlanOverall">
                <div className="col-12">
                    <div className="d-md-flex flex-wrap align-items-center justify-content-between UpgradePlanDoggleSection">
                        <div className="mb-md-0 mb-3 UpgradePlanh6">
                            <h6>Upgrade Plan</h6>
                        </div>
                        {subscription ? null:(<div className="d-flex align-items-center">
                            <div className="UpgradePlanMomnthly">
                                <span>Monthly</span>
                            </div>
                            <div className="d-flex align-items-center UpgradePlanDoggle" >
                                <label className="switch" for="checkbox" onClick={HandlePricing}>
                                    <input type="checkbox" id="checkbox" />
                                    <div className="slider round"></div>
                                </label>
                            </div>
                            <div className="UpgradePlanYearly">
                                <span>Yearly</span>
                            </div>
                        </div>)}
                        
                    </div>
                    <div className="d-md-flex flex-wrap justify-content-md-between justify-content-center">
                        <div className="mb-md-0 mb-3 UpgradePlanBasicSection">
                            <div>
                                <div className="UpgradePlanBasic">
                                    <span>Basic</span>
                                </div>
                                <div className="UpgradePlanFree">
                                    <span>Free</span>
                                </div>
                                <div className="UpgradePlanCompanySection">
                                    <div className="d-flex UpgradePlanCompany">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                    <div className="d-flex UpgradePlanCompany">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                    <div className="d-flex UpgradePlanCompany">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                    <div className="d-flex UpgradePlanCompany">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                    <div className="d-flex UpgradePlanCompany">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                    <div className="d-flex UpgradePlanCompany">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                </div>
                                <div className="UpgradePlanCurrentButton">
                                    <span className="d-flex align-items-center justify-content-center">Current Plan</span>
                                </div>
                            </div>
                        </div>
                        <div className="UpgradePlanBasicSection">
                            {!showForm ? (<div>
                                <div className="UpgradePlanBasic">
                                    <span>Premium Plan</span>
                                </div>
                                <div className="d-flex UpgradePlanFree">
                                    <div>
                                        <span>${pricing}</span>
                                    </div>
                                    <div className="d-flex align-items-end UpgradePlanFreeh6">
                                        <h6>/{perDate}</h6>
                                    </div>
                                </div>
                                <div className="UpgradePlanCompanySection">
                                    <div className="d-flex UpgradePlanCompany UpgradePlanCompanyBG">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                    <div className="d-flex UpgradePlanCompany UpgradePlanCompanyBG">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                    <div className="d-flex UpgradePlanCompany UpgradePlanCompanyBG">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                    <div className="d-flex UpgradePlanCompany UpgradePlanCompanyBG">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                    <div className="d-flex UpgradePlanCompany UpgradePlanCompanyBG">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                    <div className="d-flex UpgradePlanCompany UpgradePlanCompanyBG">
                                        <span><i className="fa fa-check" aria-hidden="true"></i>Name of the company</span>
                                    </div>
                                </div>
                                {subscription ? null :(<div className="UpgradePlanPremiumButton">
                                    <span className="d-flex align-items-center justify-content-center" onClick={PrivateTab}>Upgrade Premium Plan</span>
                                </div>)}
                                
                            </div>) : (<div className="App">
                                {/* {<Stripe setsubscription={setsubscription} setShowForm={setShowForm}  />} */}
                            </div>)}

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
// }

export default UpgradePlan;
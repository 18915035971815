export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SELECT_UNIVERSE = "SELECT_UNIVERSE";
export const GATEKEEPERLIKES = "GATEKEEPERLIKES";
export const GATEKEEPERCONTRIBUTIONS = "GATEKEEPERCONTRIBUTIONS";
export const MYCONTRIBUTIONCOUNT = "MYCONTRIBUTIONCOUNT";
export const MYLIKESCOUNT = "MYLIKESCOUNT";
export const SINGLE_TIME = "SINGLE_TIME";
export const SELECTED_TYPE = "SELECTED_TYPE";
export const FILTER_STATUS = "FILTER_STATUS";

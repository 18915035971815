import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import users from "./user";
import universe from "./universe";
import myprofilecount from "./myprofilecount";
import mylikescount from "./mylikescount";
import subpost from "./subpost";
import filter from "./filter";

export default combineReducers({
  auth,
  message,
  users,
  universe,
  myprofilecount,
  mylikescount,
  subpost,
  filter
});

import React from "react";
import "../Pages/GateKeeper/GateKeeper.css";

function GateKeeperUniverse({universe}) {
  return (
    <div className="mb-2 GateKeeperBox">
      <div className="d-flex flex-wrap mb-2">
        <div className="col-lg-7 col-12 mb-lg-0 mb-1 GateKeeperBoxh6">
          <h6>
            {universe?.universe_title}
          </h6>
        </div>
        <div className="col-lg-5 col-12">
          <div className="d-flex right-align">
            <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
              <span>
                <i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>
                {universe?.totalLikes}
              </span>
            </div>
            <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
              <span>
                <i className="fa fa-user cursor" aria-hidden="true"></i>{universe?.universeContributors?.length}
              </span>
            </div>
            <div className="GateKeeperBoxSpan GateKeeperrandom">
              <span>
                <i className="fa fa-random cursor" aria-hidden="true"></i>
                {universe?.branchCount}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="GateKeeperBoxPara">
        <p>
        {universe?.universe_description}
        </p>
      </div>
    </div>
  );
}

export default GateKeeperUniverse;

import React from "react";
import "./MyUniversePrivate.css";

class MyUniversePrivate extends React.Component {
    render() {
        return (
            <>
                <div className="MyUniversePrivateOverall">
                    <div className="col-12 d-flex flex-wrap">
                        <div className="col MyProfileLeft">
                            <div className="accordion MyProfileAccordionoverall" id="accordionExample">
                                <div class="accordion-item MyProfileAccordion">
                                    <h2 class="accordion-header  MyProfileAccordionh2" id="headingOne">
                                        <button class="accordion-button position-relative col-12 MyProfileAccordionbutton" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <div className="d-flex align-items-center justify-content-between col-12">
                                                <div className="d-flex align-items-center">
                                                    <div className="MyProfileDownArrow">
                                                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                                                    </div>
                                                    <div>
                                                        <span>My Profile</span>
                                                    </div>
                                                </div>
                                                <div className="MyProfileA MyProfileAbg">
                                                    <a href="#">32</a>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body MyProfileAccordionUL">
                                            <ul>
                                                <li><a href="#">Personal Information</a></li>
                                                <li><a href="#">Billing Information</a></li>
                                                <li><a href="#">Payment History</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between MyProfileSpan MyProfileAccordionULbg">
                                    <div>
                                        <span>My Universes</span>
                                    </div>
                                    <div className="MyProfileA MyProfileAbg MyProfileAbg1">
                                        <a href="#">32</a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between MyProfileSpan">
                                    <div>
                                        <div><span>My Contributions</span></div>
                                    </div>
                                    <div className="MyProfileA MyProfileAbg">
                                        <a href="#">256</a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between MyProfileSpan">
                                    <div>
                                        <div><span>My Likes</span></div>
                                    </div>
                                    <div className="MyProfileA MyProfileAbg">
                                        <a href="#">12k</a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between MyProfileSpan MyProfileSpanLast">
                                    <div>
                                        <div><span>My Plan</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-12">
                            <div className="col-12">
                                <div className="col-12 d-flex align-items-center justify-content-between AddNewUniverseSection">
                                    <div className="d-flex align-items-center">
                                        <div className="Universesh6">
                                            <h6>My Universes</h6>
                                        </div>
                                        <div className="UniversesA">
                                            <a href="#">32</a>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center AddNewUniverse">
                                        <div className="universeplus">
                                            +
                                        </div>
                                        <div className="AddNewUniversespan">
                                            <span>Add New Universe</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="MyProfileRight">
                                    <div className="MyUniverseSection singleBoxBorder">
                                        <div className="MyUniversePrivate">
                                            <div className="MyUniverseh6">
                                                <h6>Private</h6>
                                            </div>
                                            <div>
                                                <span className="ListUniversesSpan">
                                                    List Of Private Universes
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mb-3 MyUniverseBox">
                                            <div className="MyUniverseBoxh6">
                                                <h6>Universe Title (a little more test to make it a realistic title)</h6>
                                            </div>
                                            <div className="d-flex col-12 MyUniverseBoxIcons">
                                                <div className="me-2 MyUniverseBoxSpan thumbsup">
                                                    <span><i className="fa fa-thumbs-up me-3 cursor" aria-hidden="true"></i>2.8K</span>
                                                </div>
                                                <div className="me-2 MyUniverseBoxSpan user">
                                                    <span><i className="fa fa-user me-3 cursor" aria-hidden="true"></i>8</span>
                                                </div>
                                                <div className="MyUniverseBoxSpan random">
                                                    <span><i class="fa fa-random me-3 cursor" aria-hidden="true"></i>108</span>
                                                </div>
                                            </div>
                                            <div>
                                                <p>Description about the universe.some more text explaining the universe.so a lot to read about it.</p>
                                            </div>
                                        </div>
                                        <div className="MyUniverseBox">
                                            <div className="MyUniverseBoxh6">
                                                <h6>Universe Title (a little more test to make it a realistic title)</h6>
                                            </div>
                                            <div className="d-flex col-12 MyUniverseBoxIcons">
                                                <div className="me-2 MyUniverseBoxSpan Universethumbsup">
                                                    <span><i className="fa fa-thumbs-up me-3 cursor" aria-hidden="true"></i>2.8K</span>
                                                </div>
                                                <div className="me-2 MyUniverseBoxSpan Universeuser">
                                                    <span><i className="fa fa-user me-3 cursor" aria-hidden="true"></i>8</span>
                                                </div>
                                                <div className="MyUniverseBoxSpan Universerandom">
                                                    <span><i class="fa fa-random me-3 cursor" aria-hidden="true"></i>108</span>
                                                </div>
                                            </div>
                                            <div>
                                                <p>Description about the universe.some more text explaining the universe.so a lot to read about it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MyUniversePrivate;
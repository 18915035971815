import React, { useState } from "react";
import "../Pages/GateKeeper/GateKeeper.css";
import Girl_img from "../assets/images/girl.png";
import { gateKeeperLikesAction } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Post } from "./GateKeeperContribution";

function GateKeeperLikes({ universePosts }) {
  return (
    <div className="GateKeeperContributionBoxSection">
      {universePosts.map((item) => {
        return <UniverseAndPosts item={item} />;
      })}
    </div>
  );
}

export default GateKeeperLikes;

function UniverseAndPosts({ item }) {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  return (
    <div className="GateKeeperContributionBoxBorder">
      <div className="d-flex justify-content-center">
        <div className="GateKeeperContributionBox">
          <div className="d-flex flex-wrap mb-2">
            <div className="col-lg-7 col-12 mb-lg-0 mb-1 GateKeeperBoxh6">
              <h6>{item.universe.universe_title}</h6>
            </div>
            <div className="col-lg-5 col-12">
              <div className="d-flex right-align">
                <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
                  <span>
                    <i
                      className="fa fa-thumbs-up cursor"
                      aria-hidden="true"
                    ></i>
                    {item?.universe?.totalLikes}
                  </span>
                </div>
                <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
                  <span>
                    <i className="fa fa-user cursor" aria-hidden="true"></i>{item?.universe?.universeContributors?.length}
                  </span>
                </div>
                <div className="GateKeeperBoxSpan GateKeeperrandom">
                  <span>
                    <i className="fa fa-random cursor" aria-hidden="true"></i>
                    {item?.universe?.branchCount}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="GateKeeperBoxPara">
            <p>{item.universe.universe_description}</p>
          </div>
        </div>
      </div>
      {item?.posts?.slice(-2)?.map((item) => {
        return <Post postInfo={item} navigation={navigation}/>;
      })}
      <div className="d-flex align-items-center justify-content-center">
        {item?.posts?.length > 2 ? <button onClick={()=>{
          dispatch(gateKeeperLikesAction(item))
          navigation('/GateKeeper/likes')
        }} className="ViewAllBtn">View All</button> : null}
      
      </div>
    </div>
  );
}
{
  /* <div className="cursor HomeBrowseContributionsDots">
          <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
        </div> */
}


import './App.css';
import React from 'react';
import { BrowserRouter , Route, Routes, useLocation,Switch, Navigate} from 'react-router-dom';

import UniverseHome from './Pages/HomeCont/HomeCont';
// import Header from './Pages/Header/Header';

import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register';
import Forget from './Pages/Forget/Forget';


import SetNew from './Pages/SetNew/SetNew';
import ResetPW from './Pages/ResetPW/ResetPW';

import HomeBrowse from './Pages/HomeBrowse/HomeBrowse';
import GateKeeper from './Pages/GateKeeper/GateKeeper';
import MyProfile from './Pages/MyProfile/MyProfile';
import MyUniverse from './Pages/MyUniverse/MyUniverse';
import UpgradePlan from './Pages/UpgradePlan/UpgradePlan';
import PrivateTab from './Pages/PrivateTab/PrivateTab';

import MyUniverseTwo from './Pages/MyUniverseTwo/MyUniverseTwo';

import MyUniversePrivate from './Pages/MyUniversePrivate/MyUniversePrivate';
import ShareDocument from './Pages/ShareDocument/ShareDocument';

import MyProfileBlock from './Pages/MyProfileBlock/MyProfileBlock';

import MyUniversePublic from './Pages/MyUniversePublic/MyUniversePublic';

import Paid from './Pages/Paid/Paid';

import MyProfilePublicBlock from './Pages/MyProfilePublicBlock/MyProfilePublicBlock';

import PublicInformation from './Pages/PublicInformation/PublicInformation';

import AboutUs from './Pages/AboutUs/AboutUs';

import ContactUs from './Pages/ContactUs/ContactUs';
import Header from './Pages/Header/Header';
import { clearMessage } from "./redux/actions/message";

import { history } from './helpers/history';

// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import { connect, useSelector } from "react-redux";
import GateKeeperLikesPage from './Pages/GateKeeperLikes';
import GatekeeperContributionPage from './Pages/GatekeeperContributions';
import PaymentPage from './Pages/Payment/Payment';

function App(){
  const user = useSelector(state=>state.auth.user)
  return (
    
    <BrowserRouter >
      <Routes>
          <Route path=''element={user?<UniverseHome/>:<Navigate to="/login" replace/>}/>  
          <Route path='/aboutus' element={user?<AboutUs />:<Navigate to="/login" replace/>} />

          <Route path='/contactus' element={user?<ContactUs />:<Navigate to="/login" replace/>} />

          <Route path='/login' element={<Login />} />
          <Route path='/register'element={<Register />}/>
          
          <Route path='/forgot-password' element={<Forget />} />
          <Route path='/setNew' element={<SetNew />} />
          <Route path='/resetPW' element={<ResetPW/>} />          
          <Route path='/HomeBrowse' element={user?<HomeBrowse/>:<Navigate to="/login" replace/>} />
          <Route path='/HomeBrowse/:id' element={<HomeBrowse/>} />
          <Route path='/MyProfile' element={user?<MyProfile />:<Navigate to="/login" replace/>} />
          <Route path='/MyUniverse' element={user?<MyUniverse />:<Navigate to="/login" replace/>} />
          <Route path='/MyUniverseTwo' element={user?<MyUniverseTwo />:<Navigate to="/login" replace/>} />

          <Route path='/GateKeeper/:id' element={user?<GateKeeper/>:<Navigate to="/login" replace/>} />
          <Route path='/GateKeeper/contributions' element={user?<GatekeeperContributionPage/>:<Navigate to="/login" replace/>} />
          <Route path='/GateKeeper/likes' element={user?<GateKeeperLikesPage/>:<Navigate to="/login" replace/>} />
          <Route path='/MyUniversePrivate' element={user?<MyUniversePrivate />:<Navigate to="/login" replace/>} />
          
          <Route path='/MyUniversePublic' element={user?<MyUniversePublic/>:<Navigate to="/login" replace/>} />

          <Route path='/Paid' element={user?<Paid/>:<Navigate to="/login" replace/>} />
          <Route path='/MyProfileBlock' element={user?<MyProfileBlock/>:<Navigate to="/login" replace/>} />
          <Route path='/MyProfilePublicBlock' element={user?<MyProfilePublicBlock/>:<Navigate to="/login" replace/>} />
        
          <Route path='/UpgradePlan' element={user?<UpgradePlan/>:<Navigate to="/login" replace/>} />
          <Route path='/PaymentPage/:id' element={user?<PaymentPage/>:<Navigate to="/login" replace/>} />
     
          <Route path='/PrivateTab' element={user?<PrivateTab/>:<Navigate to="/login" replace/>} />
          <Route path='/ShareDocument' element={user?<ShareDocument/>:<Navigate to="/login" replace/>} />
          <Route path='/PublicInformation' element={user?<PublicInformation/>:<Navigate to="/login" replace/>} />
          <Route
      path="/*"
      element={!user ? <Navigate to="/login" replace /> : null}
    />

      </Routes>
    </BrowserRouter>
  )
}
class Appp extends React.Component {
  //  const location = useLocation()
  constructor(props) {
    super(props);
    // this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    const user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }


render(){
  const { currentUser, showModeratorBoard, showAdminBoard } = this.state;


  return (
    
    <BrowserRouter history={history}>
    
    {/* <Header/> */}
      <Routes>
          <Route path=''element={<UniverseHome/>}/>  
          <Route path='/aboutus' element={<AboutUs />} />

          <Route path='/contactus' element={<ContactUs />} />

          <Route path='/login' element={<Login />} />
          <Route path='/register'element={<Register />}/>
          
          <Route path='/forget' element={<Forget />} />
          <Route path='/setNew' element={<SetNew />} />
          <Route path='/resetPW' element={<ResetPW/>} />          
          <Route path='/HomeBrowse' element={<HomeBrowse/>} />
          <Route path='/MyProfile' element={<MyProfile />} />
          <Route path='/MyUniverse' element={<MyUniverse />} />
          <Route path='/MyUniverseTwo' element={<MyUniverseTwo />} />

          <Route path='/GateKeeper' element={<GateKeeper/>} />
          <Route path='/MyUniversePrivate' element={<MyUniversePrivate />} />
          
          <Route path='/MyUniversePublic' element={<MyUniversePublic/>} />

          <Route path='/Paid' element={<Paid/>} />
          <Route path='/MyProfileBlock' element={<MyProfileBlock/>} />
          <Route path='/MyProfilePublicBlock' element={<MyProfilePublicBlock/>} />
        
          <Route path='/UpgradePlan' element={<UpgradePlan/>} />
     
          <Route path='/PrivateTab' element={<PrivateTab/>} />
          <Route path='/ShareDocument' element={<ShareDocument/>} />
          <Route path='/PublicInformation' element={<PublicInformation/>} />
      

      </Routes>
    </BrowserRouter>
  );
}


}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
import React, { useState } from "react";
import "./Forget.css";
import login_image from "../../assets/images/login.png";
import { useNavigate } from "react-router-dom";
import {
  forgotPassword,
  resetForgotPassword,
  verifyOTP,
} from "../../services/authentication.service";
import { toast } from "react-toastify";

function Forget() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showOTPField, setShowOtpField] = useState(false);
  const [otp, setOtp] = useState("");
  const [showNewPassword, setShowNewPassword] = useState("");
  const [password, setPassword] = useState("");
  function resetAndGoToLogin() {
    setShowNewPassword(false);
    setShowOtpField(false);
    navigate("/login");
  }
  function isValidEmail(email) {
    console.log(email); // This should now log the current value of 'email'
    const emailPattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailPattern.test(email);
  }

  async function sendOTP() {
    try {
      if (!isValidEmail(email)) {
        toast.error("Invalid email");
        return;
      }
      const response = await forgotPassword({ email });
      toast.success(response?.message);
      setShowOtpField(true);
    } catch (e) {
      setShowOtpField(false);
      toast.error(e?.response?.data?.message || "Something went wrong");
      console.log(e);
    }
  }
  async function verifyOTPHandler() {
    try {
      if (!otp) {
        toast.error("Invalid OTP");
        return;
      }
      const response = await verifyOTP({ email, otp });
      toast.success(response?.message);
      setShowNewPassword(true);
      setShowOtpField(false);
    } catch (e) {
      setShowNewPassword(false);
      toast.error(e?.response?.data?.message || "Something went wrong");
      console.log(e);
    }
  }
  async function resetForgotPasswordHandler() {
    try {
      if (!password.trim()) {
        toast.error("Invalid password");
        return;
      }
      const response = await resetForgotPassword({
        email,
        newPassword: password,
      });
      toast.success(response?.message);
      resetAndGoToLogin();
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      console.log(e);
    }
  }

  return (
    <>
      <div className="overallwrapper">
        <div className="d-flex flex-wrap loginInnerWrapper">
          <div className="col-6 d-lg-flex d-none align-items-center justify-content-center position-relative loginleft">
            <div className="loginEllipse"></div>
            <div className="position-absolute loginImg">
              <img src={login_image} />
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="d-flex justify-content-center logincont">
              <div>
                <h5>Forgot Password</h5>
              </div>
              {showNewPassword ? (
                <div className="logincontlabel">
                  <label>Enter new password</label>
                  <input
                    type="email"
                    className="px-4"
                    placeholder="Enter new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              ) : showOTPField ? (
                <div className="logincontlabel">
                  <label>Enter OTP</label>
                  <input
                    type="email"
                    className="px-4"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
              ) : (
                <div className="logincontlabel">
                  <label>Email</label>
                  <input
                    type="email"
                    className="px-4"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              )}
              <div>
                <button
                  type="submit"
                  className="login"
                  onClick={() => {
                    if (showNewPassword) {
                      resetForgotPasswordHandler();
                    } else if (showOTPField) {
                      verifyOTPHandler();
                    } else {
                      sendOTP();
                    }
                  }}
                >
                  Submit
                </button>
              </div>
              <div className="text-center loginRegister">
                <span>Back to</span>{" "}
                <a href="#" className="register" onClick={resetAndGoToLogin}>
                  Login!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forget;

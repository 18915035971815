// api.js

import axios from 'axios';
import { BASE_URL } from "../config/config";

export async function fetchData(endpoint, options = {}) {
  try {
    const response = await axios.get(`${BASE_URL}/${endpoint}`, options);

    // Assuming the API response is in JSON format
    return response.data;
  } catch (error) {
    // Handle error
    console.error('Error fetching data:', error);
    throw error;
  }
}

export async function postData(endpoint, data={},options = {}) {
  try {
    const response = await axios.post(`${BASE_URL}/${endpoint}`, data,options);

    // Assuming the API response is in JSON format
    return response.data;
  } catch (error) {
    // Handle error
    console.error('Error fetching data:', error);
    throw error;
  }
}

import { SET_MESSAGE, CLEAR_MESSAGE, SINGLE_TIME, SELECTED_TYPE } from "../actions/types";

const initialState = {
  selectedValue:null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SELECTED_TYPE:
      return { selectedValue: payload };

    default:
      return state;
  }
}

import React from "react";
import HomeMale_img from "../assets/images/male.jpg";
import HomeFemale_img from "../assets/images/female.jpg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { selectUniverse } from "../redux/actions/universe";
import "../Pages/HomeCont/HomeCont.css";
import { useDispatch } from "react-redux";
function Universe({ item, index, className }) {
  const dispatch = useDispatch();
  const navigation = useNavigate()
  return (
    <>
      {/* chooseboxpink class to make boxes red */}
      <NavLink
        className="mb-2 "
        to={"/HomeBrowse"}
        onClick={() => {
          dispatch(selectUniverse(item));
        }}
      >
        <div
          className={`floatleftcls ${className}  ${(index + 1) % 3 != 0 ? "me-4" : ""}`}
        >

          <h6>{item.universe_title}</h6>

          {/* "chooseboxspanpink" */}
          <div
            className={`mb-2 floatleftcls chooseboxspan `}
            onClick={() => navigation(`/GateKeeper/${item?.owner_id}`)}
          >
            <Link
              className={"colorLink"}
            >
              <span >
                <img
                  src={
                    item?.owner?.user_details[0]?.profile_path
                      ? `${process.env.REACT_APP_BASE_URL}/api/${item?.owner?.user_details[0]?.profile_path}`
                      : "/dummy.jpg"
                  }
                  className="me-2 cursor"
                />
                {/* "colorLinkPink" */}

                {item?.owner?.username}

              </span>
            </Link>
          </div>
          <div className="floatleftcls">
            <p>{item.universe_description}</p>
          </div>

          <div className="mb-2 floatleftcls">
            <div className="d-flex floatleftcls">
              <div className="me-2 chooseboxspan floatleftcls thumbsup">
                <button
                  className={"like-button " + (item.totalLikes ? "liked" : "")}
                >
                  <i className="fa fa-thumbs-up me-3 cursor" aria-hidden="true"></i>
                  {item?.totalLikes || '0'}
                </button>
              </div>
              <div className="me-2 chooseboxspan floatleftcls user">
                <span>
                  <i className="fa fa-user me-3 cursor" aria-hidden="true"></i>
                  {item?.totalContributors || '0'}
                </span>
              </div>
              <div className="chooseboxspan floatleftcls random">
                <span>
                  <i className="fa fa-random me-3 cursor" aria-hidden="true"></i>
                  {item?.totalBranches || '0'}
                </span>
              </div>
            </div>
          </div>


        </div>
      </NavLink>
    </>
  );
}

export default Universe;

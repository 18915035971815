import React from "react";
import './SetNew.css';
import login_image from '../../assets/images/login.png';
import { useNavigate } from 'react-router-dom';

// class SetNew extends React.Component {
    function SetNew() {
        const navigate = useNavigate();

const resetPW=()=>{
        navigate('/resetPW');
}
const login=()=>{
    navigate('/login');
}
    // render() {
        return (
            <>
                <div className="overallwrapper">
                    <div className="d-flex flex-wrap loginInnerWrapper">
                        <div className="col-6 d-lg-flex d-none align-items-center justify-content-center position-relative loginleft">                            
                            <div className="loginEllipse">

                            </div>
                            <div className="position-absolute loginImg">
                                <img src={login_image} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="d-flex justify-content-center logincont">
                                <div>
                                    <h5>Set New Password</h5>
                                </div>
                                <form>
                                    <div className="logincontlabel">
                                        <label>Old Password</label>
                                        <input type="email" className="px-4" placeholder="Enter Password" required />
                                    </div>
                                    <div className="logincontlabel pwinput">
                                        <label>New Password</label>
                                        <input type="password" className="px-4" placeholder="Enter Password" />
                                    </div>
                                    <div className="logincontlabel pwinput">
                                        <label>Confirm Password</label>
                                        <input type="password" className="px-4" placeholder="Enter Password" />
                                    </div>                                    
                                    <div>
                                        <button type="submit" className="login"onClick={resetPW}>Reset Password</button>
                                    </div>
                                </form>
                                <div className="text-center loginRegister">
                                    <span>Back to</span>  <a href="#" className="register"onClick={login}> Login!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
// }

export default SetNew;
import React, { useEffect, useState } from "react";
import "../Pages/MyProfile/MyProfile.css";
import GateKeeperMale_img from "../assets/images/male.jpg";
import { getEntireUniverseInfo, getUserUniverseContribution } from "../services/universe.service";
import { useDispatch, useSelector } from "react-redux";
import MyLikedPost from "./MyLikedPost";
import { gateKeeperContributionsAction } from "../redux/actions/auth";
import { useNavigate } from "react-router";
import { MYCONTRIBUTIONCOUNT } from "../redux/actions/types";

function MyContributionPage({ userId ,totalContributionCount}) {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [universeWithLikes, setuniverseWIthLikes] = useState([]);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  async function getUserUniverseContributionsByHim() {
    setuniverseWIthLikes(userId);
    try {
      setLoading(true);
      let content = await getUserUniverseContribution(
        { userId },
        user.accessToken
      );
      if (content) {
        console.log(content)
        dispatch({
          type: MYCONTRIBUTIONCOUNT,
          payload: content,
        });
        setuniverseWIthLikes(content);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }
  React.useEffect(() => {
    getUserUniverseContributionsByHim();
  }, []);
  return (
    <div className="col-lg-9 col-12">
      <div className="col-12 d-flex align-items-center justify-content-between AddNewUniverseSection">
        <div className="d-flex align-items-center">
          <div className="Universesh6">
            <h6>My Contributions</h6>
          </div>

          <div className="UniversesA">
            <a>{totalContributionCount ? totalContributionCount : 0}</a>
          </div>
        </div>
      </div>
      <div className="col-12 MyProfileRight">
        {universeWithLikes.length > 0 &&
          universeWithLikes.map((item) => {
            return (
              <div className="GateKeeperContributionBoxSection">
                <div className="GateKeeperContributionBoxBorder">
                  <Universe item={item} />
                  {item?.posts?.slice(-2)?.map((element, index) => {
                    return <MyLikedPost item={item} selectedPost={element} navigation={navigation} />;
                  })}
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  {item?.posts?.length > 2 ? (
                    <button
                      onClick={() => {
                        dispatch(gateKeeperContributionsAction(item));
                        navigation("/GateKeeper/contributions");
                      }}
                      className="ViewAllBtn"
                    >
                      View All
                    </button>
                  ) : null}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
export function Universe({ item }) {
  const user = useSelector((state) => state.auth.user);
  const [universeCounts, setUniverseCounts] = useState({});
  async function getUniverseInfo() {
    try {
      const content = await getEntireUniverseInfo(
        item.id,
        user.accessToken
      );
      setUniverseCounts(content);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
 
    getUniverseInfo();
  }, []);


  return (
    <div className="d-flex justify-content-center">
      <div className="GateKeeperContributionBox">
        <div className="d-flex flex-wrap mb-2">
          <div className="col-lg-7 col-12 mb-lg-0 mb-1 GateKeeperBoxh6">
            <h6>{universeCounts?.universe_title}</h6>
          </div>
          <div className="col-lg-5 col-12">
            <div className="d-flex right-align">
              <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
                <span>
                  <i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>
                  {universeCounts?.totalLikes}
                </span>
              </div>
              <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
                <span>
                  <i className="fa fa-user cursor" aria-hidden="true"></i>
                  {universeCounts?.totalContributors}
                </span>
              </div>
              <div className="GateKeeperBoxSpan GateKeeperrandom">
                <span>
                  <i className="fa fa-random cursor" aria-hidden="true"></i>
                  {universeCounts?.totalBranches}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="GateKeeperBoxPara">
          <p>{universeCounts?.universe_description}</p>
        </div>
      </div>
    </div>
  );
}
export default MyContributionPage;

import axios from "axios";
import { BASE_URL } from "../config/config";

const API_URL = `${BASE_URL}/api/auth/`;
class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "signin", { email, password })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    window.location.href='/login'
  }

  register(username, email, mobilenumber,password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      mobilenumber,
      password,
    });
  }

  async emailExist(email){
  return axios.post(API_URL + "already-existemail", { email:email })
  .then((response) => {
    return response;
  });
  }
}

export default new AuthService();

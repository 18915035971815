
import { Get_USER_DETAILS } from "../actions/user_details/type";
  
  const initialState = [];
  
  function userReducer(users = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
    //   case CREATE_TUTORIAL:
    //     return [...tutorials, payload];
  
      case Get_USER_DETAILS:
        return payload;
  
    //   case UPDATE_TUTORIAL:
    //     return tutorials.map((tutorial) => {
    //       if (tutorial.id === payload.id) {
    //         return {
    //           ...tutorial,
    //           ...payload,
    //         };
    //       } else {
    //         return tutorial;
    //       }
    //     });
  
    //   case DELETE_TUTORIAL:
    //     return tutorials.filter(({ id }) => id !== payload.id);
  
    //   case DELETE_ALL_TUTORIALS:
    //     return [];
  
      default:
        return users;
    }
  };
  
  export default userReducer;
import {
    Get_USER_DETAILS,
  } from "./type";
  
  import UserService from "../../../services/user.service";
  

  
  export const getUserDetails = (user_id) => async (dispatch) => {
    console.log(user_id)
    try {
      const res = await UserService.getUserDetails(user_id);
   
    } catch (err) {
      console.log(err);
    }
  };
  export const getAllUniverses = () => async (dispatch) => {
    try {
      const res = await UserService.getAllUniverses();
    
    } catch (err) {
      console.log(err);
    }
  };



  export const getUniverseByUserId = (id) => async (dispatch) => {
    try {
      const res = await UserService.getUniverseByUserId(id);
    
    } catch (err) {
      console.log(err);
    }
  };

  export const getContributionsByUserId = (id) => async (dispatch) => {
    try {
      const res = await UserService.getContributionsByUserId(id);
   
    } catch (err) {
      console.log(err);
    }
  };
  

  export const addNewUniverse = (universeData) => async (dispatch) => {
    try {
      const res = await UserService.addNewUniverse(universeData);
    
    } catch (err) {
      console.log(err);
    }
  };

  

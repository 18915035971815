import React from "react";
import './Login.css';
import login_image from "../../assets/images/login.png";
import { useNavigate,Navigate, NavLink, Link } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { connect } from "react-redux";
import { login } from "../../redux/actions/auth";
import { history } from "../../helpers/history";
import { SET_MESSAGE } from "../../redux/actions/types";

const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.state = {
            email: "",
            password: "",
            loading: false,
          };
    }
        onChangeEmail(e) {
            this.setState({
              email: e.target.value,
            });
          }

          onChangePassword(e) {
            this.setState({
            password: e.target.value,
            });
        }
        register=() =>{
          const { dispatch} = this.props;
          dispatch({
            type: SET_MESSAGE,
            payload: null,
          });
            // history.push('/Register')
            // window.location.reload();
            }
        handleLogin(e) {
            e.preventDefault();

            this.setState({
            loading: true,
            });

            this.form.validateAll();

            const { dispatch, history } = this.props;
            if (this.checkBtn.context._errors.length === 0) {
            dispatch(login(this.state.email, this.state.password))
                .then(() => {
                history.push("/");
                })
                .catch(() => {
                this.setState({
                    loading: false
                });
                });
            } else {
            this.setState({
                loading: false,
            });
            }
        }

    render() {
        const { isLoggedIn, message } = this.props;
        if (isLoggedIn) {
            return <Navigate to="/" />;
          }
        return (
            <>
                <div className="overallwrapper">
                    <div className="d-flex flex-wrap loginInnerWrapper">
                        <div className="col-6 d-lg-flex d-none align-items-center justify-content-center position-relative loginleft">
                            <div className="loginEllipse">

                            </div>
                            <div className="position-absolute loginImg">
                                <img src={login_image} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="d-flex justify-content-center logincont">
                                <div>
                                    <h5>Login to your account</h5>
                                </div>
                                
          <Form
            onSubmit={this.handleLogin}
            ref={(c) => {
              this.form = c;
            }}
          >
            <div className="logincontlabel">
              <label htmlFor="username">Email</label>
              <Input
                type="text"
                className="form-control"
                name="username"
                placeholder="Enter Email"
                value={this.state.email}
                onChange={this.onChangeEmail}
                validations={[required]}
              />
            </div>

            <div className="logincontlabel pwinput">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                placeholder="Enter Password"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required]}
              />
            </div>
            <div className="d-flex loginForgot align-items-center">
                <div className="col-6 d-flex align-items-center rememberCheckbox">
                    <label className="pointerclass"><input type="checkbox" />  Remember me</label>
                </div>
                <div className="col-6 d-flex align-items-center justify-content-end forgot">
                    {/* <a href="#" onClick={forget}  >Forget password?</a> */}

                    <Link to="/forgot-password"  >Forgot password?</Link>
                </div>
            </div>
            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>
          <div className="loginalert">
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            </div>
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
                                <div className="text-center loginRegister">
                                <span>Don't have an account?</span>  <NavLink onClick={this.register} to='/register' className="register" > Register Now!</NavLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

// }
}
function mapStateToProps(state) {
    const { isLoggedIn } = state.auth;
    const { message } = state.message;
    return {
      isLoggedIn,
      message
    };
  }
  
  export default connect(mapStateToProps)(Login);

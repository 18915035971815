import React, { useState } from "react";
import "./MyProfile.css";
import payment_img from "../../assets/images/paypal.png";
import profile_img from "../../assets/images/myprofileimg.png";
import Girl_img from "../../assets/images/girl.png";
import GateKeeperMale_img from "../../assets/images/male.jpg";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import {
  getUserDetails,
  addNewUniverse,
  getUniverseByUserId,
  getContributionsByUserId,
} from "../../redux/actions/user_details/user_details";
import { history } from "../../helpers/history";
import { connect } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import EditBilling from "../../components/EditBilling";
import EditProfileInfo from "../../components/EditProfileInfo";
import EditUserCompanyInfo from "../../components/EditUserCompanyInfo";
import { toast } from "react-toastify";
import MyUniversePage from "../../components/MyUniversePage";
import MyLikesPage from "../../components/MyLikesPage";
import MyContributionPage from "../../components/MyContributionPage";
import { getUserUniverseContribution, getUserLikedUniversePosts, checkstatus } from "../../services/universe.service";
import { MYCONTRIBUTIONCOUNT, MYLIKESCOUNT } from "../../redux/actions/types";
import { getContributionAndLikes } from "../../services/universe.service";
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    //  navigate = useNavigate();
    this.getUserDetails = this.getUserDetails.bind(this);
    this.MenuClick = this.MenuClick.bind(this);
    this.MenuPersonalClick = this.MenuPersonalClick.bind(this);
    this.MenuContributionClick = this.MenuContributionClick.bind(this);
    this.MenuLikesClick = this.MenuLikesClick.bind(this);
    this.addNewUniverse = this.addNewUniverse.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeCount = this.onChangeCount.bind(this);
    this.handleUniverse = this.handleUniverse.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.onChangeWebsite = this.onChangeWebsite.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeYearofExperience = this.onChangeYearofExperience.bind(this);
    this.onChangeAge = this.onChangeAge.bind(this);
    this.myContributionData = this.myContributionData.bind(this);
    this.onChangetype = this.onChangetype.bind(this);
    this.fileInputRef = React.createRef();
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.state = {
      selectedFile: null,
      userdata: {},
      users: JSON.parse(localStorage.getItem("user")),
      userUniversesData: [],
      universeWithLikes: [],
      activemenu: "personalInformation",
      title: "",
      description: "",
      word_count: "",
      location: "",
      company_name: "",
      website: "",
      age: "",
      year_of_experience: "",
      billing_name: "",
      billing_email: "",
      billing_address_1: "",
      contact: "",
      loading: false,
      availability: "",
      name: "",
      Contributiontotalcount: 0,
      is_public:true,
      make_public:false,
      subsciption:false
    };
    //   this.props = props;
  }

  componentDidMount() {
    this.getUserDetails();
    this.getUserUniverseContributionsByHim();
    this.getUserUniversePostsLikedByHim();
    this.getUserUniverseContributionsAndLikeByHim();
    this.checksubsciption();
  }

  async checksubsciption(){
    try{
        const response=await checkstatus({userid:this.state.users.id},this.state.users.accessToken);
        console.log(response)
        this.state.subsciption=response.responce;
    }catch (e) {
        console.log(e);
      }
  }
  clickFileUploadRef() {
    this.fileInputRef.current.click()
  }
  async handleFileChange(event) {
    const selectedFile = event.target.files[0];
    this.setState({
      selectedFile: selectedFile,
    });
    const formData = new FormData();
    formData.append("profile", selectedFile);
    formData.append("userId", this.props.user.id);
    const fetchedInfo = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/update/image`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${this.props.user.accessToken}`,
        },
      }
    );
    this.getUserDetails();
    toast.success("Profile picture updated!");
  }

  async handleUpload() {
    // Perform your upload logic here
    if (this.state.selectedFile) {
      // Example: You can use FormData to send the file to an API
      const formData = new FormData();
      formData.append("profile", this.state.selectedFile);
      const fetchedInfo = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/update/image`,
        {
          method: "POST",
          body: formData,
        }
      );
      this.getUserDetails();
      // Perform your upload API call here
      // Example using fetch:
      // fetch('upload-api-url', {
      //   method: 'POST',
      //   body: formData,
      // })
      //   .then(response => response.json())
      //   .then(data => {
      //     // Handle the response from the server
      //   })
      //   .catch(error => {
      //     // Handle errors
      //   });
    }
  }
  getUserDetails() {
    const { dispatch } = this.props;
    dispatch(getUserDetails(this.state.users.id))
      .then((res) => {
        let userdata = JSON.parse(localStorage.getItem("user_data"));
        console.log(userdata)
        this.setState({
          userdata: userdata,
          company_name: userdata.company_name,
          website: userdata.website,
          location: userdata.location,
          age: userdata.age,
          year_of_experience: userdata.year_of_experience,
          billing_name: userdata.billing_name,
          billing_email: userdata.billing_email,
          billing_address_1: userdata.billing_address_1,
          billing_contact: userdata.billing_contact,
          contact: userdata.contact,
          availability: userdata.availability,
          userUniversesData: userdata.userCreatedUniverses,
          name: userdata.name
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  async getUserUniverseContributionsAndLikeByHim() {
    try {
      // setLoading(true);
      let userId = this.props.user.id;
      let accessToken = this.props.user.accessToken;
      let content = await getContributionAndLikes(
        { userId: parseInt(userId) },
        accessToken
      );
      if (content) {
        console.log(content);

        // setuniverseWIthLikes(content);
        this.setState({
          Contributiontotalcount: content['totalContributionCount'],
        });
      }
      // setLoading(false);
    } catch (e) {
      console.log(e);
      // setLoading(false);
    }
  }


  async getUserUniverseContributionsByHim() {
    const { dispatch } = this.props;
    let userId = this.props.user.id;
    let accessToken = this.props.user.accessToken;
    try {
      // setLoading(true);
      let content = await getUserUniverseContribution(
        { userId },
        accessToken
      );
      if (content) {
        console.log(content)
        dispatch({
          type: MYCONTRIBUTIONCOUNT,
          payload: content,
        });
        // setuniverseWIthLikes(content);
        this.setState({
          universeWithLikes: content,
        });
      }
      // setLoading(false);
    } catch (e) {
      console.log(e);
      // setLoading(false);
    }
  }

  async getUserUniversePostsLikedByHim() {
    const { dispatch } = this.props;
    let userId = this.props.user.id;
    let accessToken = this.props.user.accessToken;
    try {
      let content = await getUserLikedUniversePosts(
        { userId: userId },
        accessToken
      );
      if (content) {
        console.log(content);
        const initialValue = 0;
        const postLikesLength = content.map(val => val.posts.length).reduce((accumulator, currentValue) => accumulator + currentValue, initialValue)
        console.log(postLikesLength)
        dispatch({
          type: MYLIKESCOUNT,
          payload: postLikesLength,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleUniverse(e) {
    e.preventDefault();
    this.form.validateAll();
    if (this.state.title && this.state.description && this.state.word_count) {
      const { dispatch } = this.props;
      let universeData = {};
      universeData.universe_title = this.state.title;
      universeData.universe_description = this.state.description;
      universeData.word_limit = parseInt(this.state.word_count);
      universeData.owner_id = this.state.users.id;
      universeData.is_public = this.state.is_public;
      universeData.make_public = this.state.make_public;
      console.log(universeData)
      dispatch(addNewUniverse(universeData))
        .then((res) => {
          this.setState({
            userdata: JSON.parse(localStorage.getItem("user_data")),
            title: '',
            description: '',
            word_count: ''
          });
          this.MenuClick();
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
        });
    }

  }

  editProfile(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    let profileData = {};
    profileData.company_name = this.state.company_name;
    profileData.website = this.state.website;
    profileData.location = this.state.location;
    profileData.age = this.state.age;
    profileData.year_of_experience = this.state.year_of_experience;
    profileData.billing_name = this.state.billing_name;
    profileData.billing_email = this.state.billing_email;
    profileData.billing_address_1 = this.state.billing_address_1;
    profileData.contact = this.state.contact;
  }

  MenuClick = () => {
    const { dispatch } = this.props;

    dispatch(getUniverseByUserId(this.state.users.id))
      .then((res) => {
        this.getUserDetails();
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
    this.setState({
      activemenu: "My Universes",
    });
  };
  setLoading = (bool) => {
    this.setState({
      loading: bool,
    });
  };
  onChangeName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };
  myContributionData = () => {
    const { dispatch } = this.props;

    dispatch(getContributionsByUserId(this.state.users.id))
      .then((res) => {
        this.setState({
          userContibutionData: JSON.parse(
            localStorage.getItem("selectedUserContributionData")
          ),
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
    this.setState({
      activemenu: "My Universes",
    });
  };

  addNewUniverse = () => {
    this.setState({
      activemenu: "Add New Universe",
    });
  };
  EditProfile = () => {
    this.setState({
      activemenu: "EditProfile",
    });
  };
  EditPersonalInformation = () => {
    this.setState({
      activemenu: "EditPersonalInformation",
    });
  };
  EditBillingInformation = () => {
    this.setState({
      activemenu: "EditBillingInformation",
    });
  };

  MenuPersonalClick = () => {
    this.setState({
      activemenu: "personalInformation",
    });
  };
  MenuContributionClick = () => {
    const { dispatch } = this.props;

    dispatch(getContributionsByUserId(this.state.users.id))
      .then((res) => {
        this.setState({
          userContibutionData: JSON.parse(
            localStorage.getItem("selectedUserContributionData")
          ),
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
    this.setState({
      activemenu: "My Contributions",
    });
  };
  MenuLikesClick = () => {
    this.setState({
      activemenu: "My Likes",
    });
  };
  onChangeTitle = (e) => {
    this.setState({
      title: e.target.value,
    });
  };

  onChangeDescription = (e) => {
    this.setState({
      description: e.target.value,
    });
  };
  onChangetype = (e) => {
    if(e.target.value == "0"){
      this.setState({
        is_public: false,
        make_public:false
      });
    }else{
      this.setState({
        is_public: true,
        make_public:true
      });
    }
    
  };
  onChangeCount = (e) => {
    this.setState({
      word_count: e.target.value,
    });
  };
  onChangeAvailability = (e) => {
    this.setState({
      availability: e.target.value,
    });
  };
  onChangeCompany = (e) => {
    this.setState({
      company_name: e.target.value,
    });
  };

  onChangeWebsite = (e) => {
    this.setState({
      website: e.target.value,
    });
  };
  onChangeYearofExperience = (e) => {
    let yearOfExperience = isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      year_of_experience: yearOfExperience,
    });
  };
  onChangeAge = (e) => {
    this.setState({
      age: e.target.value ? parseInt(e.target.value) : "",
    });
  };
  onChangeBillingName = (e) => {
    this.setState({
      billing_name: e.target.value,
    });
  };
  onChangeBillingEmail = (e) => {
    this.setState({
      billing_email: e.target.value,
    });
  };
  onChangeBillingAddress = (e) => {
    this.setState({
      billing_address_1: e.target.value,
    });
  };
  onChangeBillingContact = (e) => {
    this.setState({
      billing_contact: e.target.value,
    });
  };
  onChangeLocation = (e) => {
    this.setState({
      location: e.target.value,
    });
  };

  

  render() {
    const { isLoggedIn, message } = this.props;

    return (
      <>
        <Header />
        <div className="MyProfileOverAll">
          <div className="col-12 d-flex flex-wrap">
            <div className="col MyProfileLeft">
              <div
                className="accordion MyProfileAccordionoverall"
                id="accordionExample"
              >
                <div className="accordion-item MyProfileAccordion">
                  <h2
                    className="accordion-header  MyProfileAccordionh2"
                    id="headingOne"
                  >
                    <button
                      className="accordion-button position-relative col-12 MyProfileAccordionbutton"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <div className="d-flex align-items-center justify-content-between col-12">
                        <div className="d-flex align-items-center">
                          <div className="MyProfileDownArrow">
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div>
                            <span>My Profile</span>
                          </div>
                        </div>
                        {/* <div className="MyProfileA">
                          <a href="#">32</a>
                        </div> */}
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body MyProfileAccordionUL">
                      <ul>
                        <li
                          className={`${this.state.activemenu == "personalInformation"
                            ? "MyProfileAccordionULbg"
                            : ""
                            }`}
                          onClick={() => this.MenuPersonalClick()}
                        >
                          <a>Personal Information</a>
                        </li>
                        <li
                          className={`${this.state.activemenu == "EditBillingInformation"
                            ? "MyProfileAccordionULbg"
                            : ""
                            }`}
                          onClick={() => this.EditBillingInformation()}
                        >
                          <a>Billing Information</a>
                        </li>
                        <li>
                          <a href="#">Payment History</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className={`d-flex align-items-center justify-content-between MyProfileSpan ${this.state.activemenu == "My Universes"
                    ? "MyProfileAccordionULbg"
                    : ""
                    }`}
                  onClick={() => this.MenuClick()}
                >
                  <div>
                    <span>My Universes</span>
                  </div>
                  <div className="MyProfileA MyProfileAbg">
                    <a href="#">{this.state.userUniversesData?.length}</a>
                  </div>
                </div>
                <div
                  className={`d-flex align-items-center justify-content-between MyProfileSpan ${this.state.activemenu == "My Contributions"
                    ? "MyProfileAccordionULbg"
                    : ""
                    }`}
                  onClick={() => this.MenuContributionClick()}
                >
                  <div>
                    <div>
                      <span>My Contributions</span>
                    </div>
                  </div>
                  <div className="MyProfileA MyProfileAbg">
                    <a >{this.state.Contributiontotalcount ? this.state.Contributiontotalcount : 0}</a>
                  </div>
                </div>
                <div
                  className={`d-flex align-items-center justify-content-between MyProfileSpan ${this.state.activemenu == "My Likes"
                    ? "MyProfileAccordionULbg"
                    : ""
                    }`}
                  onClick={() => this.MenuLikesClick()}
                >
                  <div>
                    <div>
                      <span>My Likes</span>
                    </div>
                  </div>
                  <div className="MyProfileA MyProfileAbg">
                    {console.log(this.props)}
                    <a >{this.props.mylikes ? this.props.mylikes : 0}</a>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between MyProfileSpan MyProfileSpanLast">
                  <div>
                    <div>
                      <span>My Plan</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.activemenu == "personalInformation" ? (
              <div className="col-lg-9 col-12">
                <div className="col-12">
                  <div className="MyProfileRight">
                    <div className="MyProfilePictureSection">
                      <div className="MyProfileh6">
                        <h6>My Profile</h6>
                      </div>
                      <input
                        type="file"
                        ref={this.fileInputRef}
                        style={{ display: "none" }}
                        onChange={this.handleFileChange}
                      />
                      <div className="col-12 d-flex MyProfileImgPart">
                        <div className="col-lg-3 col-4 position-relative d-flex align-items-center">
                          <div
                            className="cursor MyProfileImg"
                            onClick={() => this.clickFileUploadRef()}
                          >
                            <div>
                              <img
                                src={
                                  this.state.userdata?.profile_path
                                    ? `${process.env.REACT_APP_BASE_URL}/api/${this.state.userdata?.profile_path}`
                                    : "/dummy.jpg"
                                }
                              />
                            </div>
                            <div className="MyProfileImgEdit position-absolute d-flex align-items-center justify-content-center">
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="col-12 d-flex align-items-center justify-content-between MyProfileNameSection">
                            <div className="col-6 d-flex align-items-center MyProfileConth5">
                              <h5>{this.state.users.username}</h5>
                            </div>
                            <div className="col-6 d-flex justify-content-end MyProfileContbutton">
                              <button
                                className="d-flex align-items-center justify-content-between"
                                onClick={() => this.EditProfile()}
                              >
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>{" "}
                                <span> Edit</span>
                              </button>
                            </div>
                          </div>
                          <div className="MyProfileContul">
                            <ul>
                              <li>
                                <a
                                  href="#"
                                  className="d-flex align-items-center"
                                >
                                  <i
                                    className="fa fa-building d-flex flex-wrap align-items-center justify-content-center"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {this.state.userdata?.company_name}
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="d-flex align-items-center"
                                >
                                  <i
                                    className="fa fa-map-marker d-flex align-items-center justify-content-center"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {this.state.userdata?.company_location}
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="d-flex align-items-center"
                                >
                                  <i
                                    className="fa fa-link d-flex align-items-center justify-content-center"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {this.state.userdata?.company_website}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="MyProfilePictureSection">
                      <div className="d-flex align-items-center justify-content-between MyProfileh6div">
                        <div className="MyProfileh6">
                          <h6>Personal Information</h6>
                        </div>
                        <div className="MyProfileContbutton">
                          <button
                            className="d-flex align-items-center justify-content-between"
                            onClick={() => this.EditPersonalInformation()}
                          >
                            <i className="fa fa-pencil" aria-hidden="true"></i>{" "}
                            <span> Edit</span>
                          </button>
                        </div>
                      </div>
                      <div className="MyProfileInformation">
                        <ul>
                          <li className="d-flex">
                            <div className="col-md-4 col-5">Name:</div>
                            <div className="col-md-8 col-7">
                              {this.state.userdata?.name}
                            </div>
                          </li>
                          <li className="d-flex">
                            <div className="col-md-4 col-5">Email:</div>
                            <div className="col-md-8 col-7">
                              {this.state.users?.email}
                            </div>
                          </li>
                          <li className="d-flex">
                            <div className="col-md-4 col-5">Availability:</div>
                            <div className="col-md-8 col-7">
                              {console.log(
                                this.state.userdata,
                                "{this.state.userdata"
                              )}
                              {this.state.userdata?.availability}
                            </div>
                          </li>
                          <li className="d-flex">
                            <div className="col-md-4 col-5">Age:</div>
                            <div className="col-md-8 col-7">
                              {this.state.userdata?.age}
                            </div>
                          </li>
                          <li className="d-flex">
                            <div className="col-md-4 col-5">Location:</div>
                            <div className="col-md-8 col-7">
                              {this.state.userdata?.location}
                            </div>
                          </li>
                          <li className="d-flex">
                            <div className="col-md-4 col-5">
                              Year Experience:
                            </div>
                            <div className="col-md-8 col-7">
                              {this.state.userdata?.year_of_experience
                                ? `${this.state.userdata?.year_of_experience} Year
                              Experience`
                                : ""}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="MyProfilePictureSection">
                      <div className="d-flex align-items-center justify-content-between MyProfileh6div">
                        <div className="MyProfileh6">
                          <h6>Billing Information</h6>
                        </div>
                        <div className="MyProfileContbutton">
                          <button
                            className="d-flex align-items-center justify-content-between"
                            onClick={() => this.EditBillingInformation()}
                          >
                            <i className="fa fa-pencil" aria-hidden="true"></i>{" "}
                            <span> Edit</span>
                          </button>
                        </div>
                      </div>
                      <div className="MyProfileInformation">
                        <ul>
                          <li className="d-flex">
                            <div className="col-md-4 col-5">Name:</div>
                            <div className="col-md-8 col-7">
                              {this.state.userdata?.billing_name}
                            </div>
                          </li>
                          <li className="d-flex">
                            <div className="col-md-4 col-5">Email:</div>
                            <div className="col-md-8 col-7">
                              {this.state.userdata?.billing_email}
                            </div>
                          </li>
                          <li className="d-flex">
                            <div className="col-md-4 col-5">Address:</div>
                            <div className="col-md-8 col-7">
                              {this.state.userdata?.billing_address_1}
                            </div>
                          </li>
                          <li className="d-flex">
                            <div className="col-md-4 col-5">Contact:</div>
                            <div className="col-md-8 col-7">
                              {this.state.userdata?.billing_contact}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="MyProfilePictureSection LastBoxBorder">
                      <div className="d-flex align-items-center justify-content-between MyProfileh6div">
                        <div className="MyProfileh6">
                          <h6>Payment History</h6>
                        </div>
                        <div className="MyProfileContbutton">
                          <button>
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                            <span>Edit</span>
                          </button>
                        </div>
                      </div>
                      <div className="MyProfileInformation cardpart">
                        <ul>
                          <li className="d-flex">
                            <div className="col-md-4 col-6">
                              Payment Amount:
                            </div>
                            <div className="col-md-8 col-6 PaymentAmount">
                              $20
                            </div>
                          </li>
                          <li className="d-flex">
                            <div className="col-md-4 col-6">
                              Payment Method:
                            </div>
                            <div className="col-md-8 col-6">
                              <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center MyProfilecard">
                                  <i
                                    className="fa fa-credit-card"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div className="MyProfileInformationimg">
                                  <img src={payment_img} />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="d-flex">
                            <div className="col-md-4 col-6">
                              Payment Transaction Id:
                            </div>
                            <div className="col-md-8 col-6">txn_123456789</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.activemenu == "EditProfile" ? (
              <EditUserCompanyInfo
                userdata={this.state.userdata}
                getUsetInfo={this.getUserDetails}
                setLoading={this.setLoading}
                loading={this.state.loading}
                MenuPersonalClick={this.MenuPersonalClick}
                handleFileChange={this.handleFileChange}
              />
            ) : null}

            {this.state.activemenu == "EditPersonalInformation" ? (
              <EditProfileInfo
                onChangeName={this.onChangeName}
                nameValue={this.state.name}
                availability={this.state.availability}
                onChangeAvailability={this.onChangeAvailability}
                getUsetInfo={this.getUserDetails}
                setLoading={this.setLoading}
                loading={this.state.loading}
                MenuPersonalClick={this.MenuPersonalClick}
                onChangeYearofExperience={this.onChangeYearofExperience}
                onChangeLocation={this.onChangeLocation}
                onChangeAge={this.onChangeAge}
                editProfile={this.editProfile}
                location={this.state.location}
                age={this.state.age}
                year_of_experience={this.state.year_of_experience}
              />
            ) : null}

            {this.state.activemenu == "EditBillingInformation" ? (
              <EditBilling
                getUsetInfo={this.getUserDetails}
                setLoading={this.setLoading}
                MenuPersonalClick={this.MenuPersonalClick}
                loading={this.state.loading}
                onChangeBillingContact={this.onChangeBillingContact}
                billing_contact={this.state.billing_contact}
                billing_address_1={this.state.billing_address_1}
                onChangeBillingAddress={this.onChangeBillingAddress}
                billing_email={this.state.billing_email}
                onChangeBillingEmail={this.onChangeBillingEmail}
                onChangeBillingName={this.onChangeBillingName}
                billing_name={this.state.billing_name}
                editProfile={this.editProfile}
              />
            ) : null}

            {this.state.activemenu == "My Universes" ? (
              <MyUniversePage
                userUniversesData={this.state.userUniversesData}
                addNewUniverse={this.addNewUniverse}
                purchaseStatus={this.state.userdata.purchaseStatus}
              />
            ) : null}

            {this.state.activemenu == "Add New Universe" ? (
              <div className="col-lg-9 col-12">
                <div className="col-12">
                  <div className="col-12 d-flex align-items-center justify-content-between AddNewUniverseSection">
                    <div className="d-flex align-items-center">
                      <div className="Universesh6">
                        <h6>Add New Universe</h6>
                      </div>
                    </div>
                    <div className="d-flex align-items-center AddNewUniverse">
                      <div className="universeplus">+</div>
                      <div className="AddNewUniversespan">
                        <span>Add New Universe</span>
                      </div>
                    </div>
                  </div>
                  <div className="MyProfileadd">
                    <Form
                      onSubmit={(e) => this.handleUniverse(e)}
                      ref={(c) => {
                        this.form = c;
                      }}
                    >
                      <div className="logincontlabel">
                        <label htmlFor="username">Universe Title</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="title"
                          placeholder="Enter Universe Title"
                          value={this.state.title}
                          onChange={this.onChangeTitle}
                          validations={[required]}
                        />
                      </div>

                      <div className="logincontlabel pwinput">
                        <label htmlFor="password">Universe Description</label>
                        <Input
                          type="description"
                          className="form-control"
                          name="password"
                          placeholder="Enter Universe Description"
                          value={this.state.description}
                          onChange={this.onChangeDescription}
                          validations={[required]}
                        />
                      </div>

                      <div className="logincontlabel pwinput">
                        <label htmlFor="password">Universe Type</label>
                        <select className="form-control" onChange={this.onChangetype} >
                        <option value=""></option>
                          <option value="1">Public</option>
                          {this.state.subsciption ? (<option value="0">Private</option>):null}
                          
                        </select>
                        {/* <Input
                          type="description"
                          className="form-control"
                          name="password"
                          placeholder="Enter Universe Description"
                          value={this.state.description}
                          onChange={this.onChangeDescription}
                          validations={[required]}
                        /> */}
                      </div>

                      <div className="logincontlabel pwinput">
                        <label htmlFor="password">Word Count</label>
                        <Input
                          type="number"
                          className="form-control"
                          name="word_count"
                          placeholder="Enter word count "
                          value={this.state.word_count}
                          onChange={this.onChangeCount}
                          validations={[required]}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          className="btn btn-primary btn-block"
                          disabled={this.state.loading}
                        >
                          {this.state.loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          <span>AddNewUniverse</span>
                        </button>
                      </div>
                      <div className="unviersealert">
                        {message && (
                          <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                              {message}
                            </div>
                          </div>
                        )}
                      </div>
                      <CheckButton
                        style={{ display: "none" }}
                        ref={(c) => {
                          this.checkBtn = c;
                        }}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.activemenu == "My Contributions" ? (
              <MyContributionPage userId={this.props.user.id} totalContributionCount={this.state.Contributiontotalcount} />
            ) : null}

            {this.state.activemenu == "My Likes" ? (
              <MyLikesPage userId={this.props.user.id} />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, user } = state.auth;
  const { mycontribution } = state.myprofilecount;
  const { mylikes } = state.mylikescount;
  const { message } = state.message;

  return {
    isLoggedIn,
    message,
    user,
    mycontribution,
    mylikes,
  };
}

export default connect(mapStateToProps)(MyProfile);

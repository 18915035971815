import { MYCONTRIBUTIONCOUNT } from "../actions/types";

const initialState = {
    mycontribution:null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
    console.log(type,payload)
  switch (type) {
    case MYCONTRIBUTIONCOUNT:
      return { mycontribution: payload };

    default:
      return state;
  }
}

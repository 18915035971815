import { FILTER_STATUS } from "../actions/types";

const initialState = {
  filterStatus:false
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FILTER_STATUS:
      return { filterStatus: payload };

    default:
      return state;
  }
}

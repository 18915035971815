import { fetchData, postData } from "./api.service";

const USER = JSON.parse(localStorage.getItem("user"));


export const getAllUniverses = (id,token) => {
  return postData('api/getAllUniverses', id,{
    headers: { "Authorization": `Bearer ${token}` },
  });
};

export const getOneUniverse = (id,token) => {
  return fetchData(`api/getUniverseByUniverseId/${id}`, {
    headers: { "Authorization": `Bearer ${token}` },
  });
};
export const getEntireUniverseInfo = (id,token) => {
  return fetchData(`api/universe/${id}`, {
    headers: { "Authorization": `Bearer ${token}` },
  });
};
export const getContributors = (id,token) => {
  return fetchData(`api/getContributors/${id}`, {
    headers: { "Authorization": `Bearer ${token}` },
  });
};
export const getSinglePostInfo = (id,token) => {
  return fetchData(`api/posts/${id}`, {
    headers: { "Authorization": `Bearer ${token}` },
  });
};

export const createNewPost = (data,token) => {
  return postData('api/addNewPost', data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const sharePost = (data,token) => {
  return postData('api/share-post', data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getUserLikedUniversePosts = (data,token) => {
  return postData('api/user-universe-posts-likes', data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getUserUniverseContribution = (data,token) => {
  return postData('api/user-universe-contribution', data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getContributionAndLikes = (data,token) => {
  return postData('api/user-contribution-likes', data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getSharePost = (data,token) => {
  return postData('api/share-post-info', data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const likePostInUniverse = (data,token) => {
  return postData('api/like-post', data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getsubPosts = (data,token) => {
  return postData('api/posts/popup/',data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const payment = (data,token) => {
  return postData('api/create-purchase',data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const createInvitee = (data,token) => {
  return postData('api/create-invitee',data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getInvitee = (data,token) => {
  return postData('api/get-invitee',data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateInvitee = (data,token) => {
  return postData('api/update-invitee',data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const checkstatus = (data,token) => {
  return postData('api/check-purchase',data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};


export const getRole= (data,token) => {
  return fetchData('api/get-role',data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const checkPermission = (data,token) => {
  return postData('api/check-permission',data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateUniverse = (data,token) => {
  return postData('api/update-makepublic',data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

  export const createIntent = (data,token) => {
    return postData('api/create-payment-intent',data,{
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };

import React, { useState, useEffect } from "react";
import "./HomeCont.css";
import Header from "../Header/Header";
import Universe from "../../components/Universe";
import { getAllUniverses } from "../../services/universe.service";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth";
import { useNavigate } from "react-router";

function UniverseHome() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch()
  const [universes, setUniverses] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate()
  useEffect(() => {
    if (user) {
      getUniverses();
    }
  }, [user]);
  useEffect(() => {
    if (user) {
     const sharedPostId = localStorage.getItem("SharedPost")
     if(sharedPostId){
      localStorage.removeItem("SharedPost")
      window.setTimeout(()=>{
        navigation(`/HomeBrowse/${sharedPostId}`)
      },1000)
     }
    }
  }, [user]);
  async function getUniverses() {
    try {
      setLoading(true);
      console.log(user.id)
      let content = await getAllUniverses({user_id:user.id,email:user.email},user.accessToken);
      if (content?.universes_data) {
        setUniverses(content?.universes_data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e.response.status);
      if (e.response.status === 401) {
        dispatch(logout());
        localStorage.removeItem("user");
        window.location.href = "/login";
      }
      setLoading(false);
    }
  }
  return (
    <>
      {loading ? <Loader /> : null}
      <Header />
      <div className="homecontoverall">
        <div className="py-3 d-flex justify-content-between choose">
          <div className="col-6 d-flex align-items-center">
            <h5>Choose a Universe</h5>
          </div>
          <div className="col-6 d-flex align-items-center justify-content-end">
            <button>
              <i className="fa fa-search me-3" aria-hidden="true"></i>
            </button>
            <button>
              <i className="fa fa-filter" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div className="col-12 d-flex flex-wrap floatleftclass chooseboxCont">
          {universes?.map((item, index) => (
            <Universe key={index} item={item} index={index} className={item.is_public ? 'chooseboxpublic':'chooseboxprivate'} readonly={item.readonly} />
          ))}
        </div>
      </div>
    </>
  );
}

export default UniverseHome;

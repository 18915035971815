import React from "react";
import { createNewPost } from "../services/universe.service";
import { useDispatch, useSelector } from "react-redux";
import { SELECTED_TYPE, SINGLE_TIME } from "../redux/actions/types";

function CreateNewPost({
  parent,
  wordLimit,
  setLoading,
  setPostManagement,
  setSelectedPost,
  setShowNewPostBox,
  universeInfo,
  postManagement,
  level,
  setUniverseInfo,
  setUpdateUniverseFlag,
  roledata
}) {
  const selectedUniverse = useSelector(
    (state) => state.universe.selectedUniverse
  );
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [value, setValue] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [wordcount, setwordCount] = React.useState(524288);
  async function createPostForUniverse() {
    const parentId = parent || 0;
    setLoading(true);

    try {
      const response = await createNewPost(
        {
          title: "post 6",
          content: value,
          universe_id: universeInfo.id,
          author_id: user.id,
          word_count: value.length,
          priority: 1,
          parentId: parentId,
          role_id:roledata
        },
        user.accessToken
      );
      const newPost = response.universe_data;
      console.log(newPost)
      newPost.username = user.username;
      dispatch({
        type: SELECTED_TYPE,
        payload: newPost,
      });
      setSelectedPost(newPost);
      setUniverseInfo((prev) => ({ ...prev, posts: [...prev.posts, newPost] }))
      setPostManagement((prevProjects) => {
        const updatedProjects = prevProjects.map((project) => {
          if (project.parentId === parentId) {
            const updatedTasks = [...project.posts, newPost];
            return { ...project, posts: updatedTasks };
          }
          return project;
        });

        return updatedProjects;
      });
      let findLevel = postManagement.findIndex(
        (item) => item.level === level + 1
      );
      if (findLevel === -1) {
        console.log("inside")
        setPostManagement((prev) => [
          ...prev,
          {
            level: level + 1,
            posts: universeInfo?.posts.filter(
              (post) => post.parentId === newPost.id
            ),
            parentId: newPost.id || 0,
          },
        ]);
      } else {
        console.log("outside")
        setPostManagement((prevArray) => {
          let newArray = [...prevArray];
          newArray[findLevel] = {
            level: level + 1,
            posts: universeInfo?.posts.filter(
              (post) => post.parentId === newPost.id
            ),
            parentId: newPost.id,
          };
          let newArrayvalue = newArray.findIndex(s => s.posts.length == 0);
          let mapnewAarr = newArray.filter((v, index) => index <= newArrayvalue)
          console.log(mapnewAarr)
          return mapnewAarr;
        });
      }
      dispatch({
        type: SINGLE_TIME,
        payload: false,
      });

      setShowNewPostBox(false);
      setLoading(false);
      setUpdateUniverseFlag((prev) => !prev);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }
  return (
    <div className="col-12 HomeBrowseInputBox">
      <div className="col-12 mb-1">
        <div className="PostHeader">
          <span>Post what happens next!</span>
        </div>
      </div>
      <div className="HomeBrowseTextareaPart">
        <textarea
          className="form-control HomeBrowseTextarea"
          value={value}
          maxLength={wordcount}
          onChange={(e) => {
            const fieldValue = e.target.value;
            console.log(fieldValue.match(/\S+/g))
            let numOfWords;
            if(fieldValue.match(/\S+/g) != null){
              
              fieldValue
                ? (numOfWords = fieldValue.match(/\S+/g).length)
                : (numOfWords = 0);
              console.log(numOfWords, wordLimit);
              if (numOfWords == parseInt(wordLimit)) {
                console.log("inside", numOfWords, parseInt(wordLimit) + 1, fieldValue.length, fieldValue.trim().length);
                const lastWord = fieldValue.split(' ').pop();
                console.log(!lastWord.replace(/\s/g, '').length);
                if (!lastWord.replace(/\s/g, '').length == true) {
                  setwordCount(fieldValue.trim().length-1);
                }
  
              } else {
                setwordCount(524288)
              }
            }
           
            setCount(numOfWords)
            setValue(e.target.value);
          }}
          placeholder="Empty Input"
          id="exampleFormControlTextarea1"
          rows="3"
        ></textarea>
        <h6>
          {count} / {wordLimit}
        </h6>
      </div>
      <div className="col-12 d-flex align-items-center justify-content-center">
        {/* <div className="col-lg-7 col-12 PostButtonSection"> */}
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="d-flex">
            <div className="cursor d-flex align-items-center justify-content-center PostButton PostButtonGreen">
              <button
                disabled={count > parseInt(wordLimit)}
                onClick={createPostForUniverse}
              >
                Post
              </button>
            </div>
            <div className="cursor d-flex align-items-center justify-content-center PostButton PostButtonPink">
              <button
                onClick={() => {
                  setShowNewPostBox(false);
                  dispatch({
                    type: SINGLE_TIME,
                    payload: false,
                  });
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default CreateNewPost;

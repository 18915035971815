import React, { useState } from "react";
import "./GateKeeper.css";
import GateKeeper_img from "../../assets/images/myprofileimg.png";
import Girl_img from "../../assets/images/girl.png";
import GateKeeperMale_img from "../../assets/images/male.jpg";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useSelector } from "react-redux";
import { getContributionAndLikes } from "../../services/universe.service";
import GateKeeperContribution from "../../components/GateKeeperContribution";
import GateKeeperLikes from "../../components/GateKeeperLikes";
import Loader from "../../components/Loader";
import GateKeeperUniverse from "../../components/GateKeeperUniverse";
// class GateKeeper extends React.Component {
//     render() {
function GateKeeper() {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [universeWithLikes, setuniverseWIthLikes] = useState([]);

  async function getUserUniverseContributionsAndLikeByHim() {
    try {
      setLoading(true);
      let content = await getContributionAndLikes(
        { userId: parseInt(id) },
        user.accessToken
      );
      if (content) {
        console.log(content);
        setuniverseWIthLikes(content);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }
  React.useEffect(() => {
    getUserUniverseContributionsAndLikeByHim();
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}
      <Header />
      <div className="GateKeeperOverall">
        <div>
          <div className="col-12 d-flex flex-wrap GateKeeperImgSection">
            <div className="col-md-7 col-12 mb-md-0 mb-1">
              <div className="col-12 d-flex">
                <div className="GateKeeperImage">
                  <img
                    src={
                      universeWithLikes.user_details?.profile_path
                        ? `${process.env.REACT_APP_BASE_URL}/api/${universeWithLikes.user_details?.profile_path}`
                        : "/dummy.jpg"
                    }
                  />
                </div>
                <div>
                  <div className="GateKeeperImgSectionName">
                    <h5>{universeWithLikes?.user?.username}</h5>
                  </div>
                  {universeWithLikes?.user?.id !== user?.id ? (
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center justify-content-center cursor GateKeeperBlockIcon">
                        <i className="fa fa-user-times" aria-hidden="true"></i>
                      </div>
                      <div className="GateKeeperBlockSpan">
                        <span>Block or Report user</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 mb-md-0 mb-1 d-flex justify-content-center">
              <div className="GateKeeperImgSectionIcons">
                <ul>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <i
                        className="fa fa-building d-flex align-items-center justify-content-center"
                        aria-hidden="true"
                      ></i>
                      {universeWithLikes?.user_details?.company_name}
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <i
                        className="fa fa-briefcase d-flex align-items-center justify-content-center"
                        aria-hidden="true"
                      ></i>
                      {universeWithLikes?.user_details?.availability}
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-flex align-items-center">
                      <i
                        className="fa fa-map-marker d-flex align-items-center justify-content-center"
                        aria-hidden="true"
                      ></i>{" "}
                      {universeWithLikes?.user_details?.location}
                    </a>
                  </li>
                  <li className="GateKeeperImgSectionIconsBG">
                    <a href="#" className="d-flex align-items-center">
                      <i
                        className="fa fa-link d-flex align-items-center justify-content-center"
                        aria-hidden="true"
                      ></i>{" "}
                      {universeWithLikes?.user_details?.company_website}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 GateKeeperUniversesSection">
            <div>
              <ul
                className="nav nav-pills d-flex justify-content-between"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item active" role="presentation">
                  <button
                    className="nav-link active d-flex universeButton"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <div className="GateKeeperA GateKeeperAbg">
                      <a href="#">
                        {universeWithLikes?.contributedUniverses?.length +
                          universeWithLikes?.createdUniverses?.length || 0}
                      </a>
                    </div>
                    Universes
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link d-flex universeButton"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <div className="GateKeeperA">
                      <a href="#">{universeWithLikes?.totalContributionCount}</a>
                    </div>
                    Contributions
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link d-flex universeButton"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    <div className="GateKeeperA">
                      <a href="#">{universeWithLikes?.totalLikesCount}</a>
                    </div>
                    Likes
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="GateKeeperBoxSection">
                  <div className="GateKeeperBoxBorder">
                    <div className="GateKeeperBoxSectionName">
                      <h6>GateKeeper</h6>
                    </div>
                    <div className="GateKeeperBoxSectionSubName">
                      <span>Universe that i created</span>
                    </div>
                    {universeWithLikes?.createdUniverses?.map((universe) => {
                      return <GateKeeperUniverse universe={universe} />;
                    })}
                  </div>
                  <div className="GateKeeperBoxTwo">
                    <div className="GateKeeperBoxSectionName">
                      <h6>Guardian</h6>
                    </div>
                    <div className="GateKeeperBoxSectionSubName">
                      <span>Universe that i contributed to</span>
                    </div>
                    {universeWithLikes?.contributedUniverses?.map(
                      (universe) => {
                        return <GateKeeperUniverse universe={universe} />;
                      }
                    )}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <GateKeeperContribution
                  universePosts={universeWithLikes?.contributions || []}
                />
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <GateKeeperLikes
                  universePosts={universeWithLikes?.likes || []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GateKeeper;

import { postData } from "./api.service";

export const forgotPassword = (data) => {

  return postData("api/auth/forgot-password", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const verifyOTP = (data) => {

  return postData("api/auth/verify-otp", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const resetForgotPassword = (data) => {

  return postData("api/auth/reset-forgot-password", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

import React from "react";

import "../Pages/MyProfile/MyProfile.css";
import UniversePageComponent from "./UniversePageComponent";
import { Outlet, Link, NavLink, useNavigate } from "react-router-dom";

function MyUniversePage({ userUniversesData, addNewUniverse, purchaseStatus }) {
  const navigate = useNavigate();

  return (
    <div className="col-lg-9 col-12">
      <div className="col-12">
        <div className="col-12 d-flex align-items-center justify-content-between AddNewUniverseSection">
          <div className="d-flex align-items-center">
            <div className="Universesh6">
              <h6>My Universes</h6>
            </div>
            <div className="UniversesA">
              <a href="#">{userUniversesData?.length}</a>
            </div>
          </div>
          <div
            className="d-flex align-items-center AddNewUniverse"
            onClick={() => addNewUniverse()}
          >
            <div className="universeplus">+</div>
            <div className="AddNewUniversespan">
              <span>Add New Universe</span>
            </div>
          </div>
        </div>
        <div className="MyProfileRight">
          <div className="MyUniverseSection">
            <div className="MyUniversePrivate">
              <div className="MyUniverseh6">
                <h6>Private</h6>
              </div>
              <div>
                <span className="ListUniversesSpan">
                  List Of Private Universes
                </span>
              </div>
            </div>
              {purchaseStatus ? (<>{userUniversesData?.length > 0
              ? userUniversesData.map((item, index) => !item.is_public && (
                  <UniversePageComponent item={item} key={index} className={'mb-3 MyUniverseBoxprivate'} is_public={false}/>
                ))
              : null}</>):(<> <div className="col-12 d-flex TimeUpgradeSection">
              <div className="col-1 position-relative TimeUpgradeLogo">
                <i
                  className="fa fa-bolt position-absolute"
                  aria-hidden="true"
                ></i>
              </div>
              <div>
                <div className="TimeUpgradeh5">
                  <h5>Time to Upgrade</h5>
                </div>
                <div className="TimeUpgradeP">
                  <p>
                    Some message about upgrading to the paid package to have
                    accesss to paid features as well as private universes.
                  </p>
                </div>
              </div>
            </div>
            <div className="upgratenowspan">
              <button onClick={()=> navigate("/UpgradePlan")}>
              <span>Upgrade Now</span>
              </button>
              
            </div></>)}
           
          </div>
          <div className="MyUniverseSection LastBoxBorder">
            <div className="MyUniversePrivate">
              <div className="MyUniverseh6">
                <h6>Public</h6>
              </div>
              <div>
                <span className="ListUniversesSpan">
                  List Of Public Universes
                </span>
              </div>
            </div>
            {userUniversesData?.length > 0
              ? userUniversesData.map((item, index) => item.is_public && (
                  <UniversePageComponent item={item} key={index} className={'mb-3 MyUniverseBoxpublic'} is_public={true}/>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyUniversePage;

import React, { useState, useEffect } from "react";
import "./HomeBrowse.css";
import HomeMale_img from "../../assets/images/male.jpg";
import Header from "../Header/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getEntireUniverseInfo,
  getOneUniverse,
  getSharePost,
} from "../../services/universe.service";
import CreateNewPost from "../../components/CreateNewPost";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import UniverseParentPost from "../../components/UniverseParentPost";
import InitialNoPost from "../../components/InitialNoPost";
import Pagination from "../../components/Pagination";
import Branch from "../../components/Branch";
import Sidebar from "../../components/sidebar-posts/Sidebar";
import { SINGLE_TIME } from "../../redux/actions/types";

function HomeBrowse() {
  const [showSidebar, setShowSideBar] = useState(false);
  const [originalPostsLevel, setOriginalPostsLevel] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigation = useNavigate();
  function disable() {
    window.document.body.style.overflowY = "hidden";
  }

  function enable() {
    window.document.body.style.overflowY = "auto";
  }
  const selectedUniverse = useSelector(
    (state) => state.universe.selectedUniverse
  );
  const [selectedPost, setSelectedPost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [universeInfo, setUniverseInfo] = useState(false);
  const [postManagement, setPostManagement] = useState([]);
  const [universeCounts, setUniverseCounts] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [updateUniverseFlag, setUpdateUniverseFlag] = useState(false);

  const [showpost, setshowpost] = useState(false);
  const [showmoreCount, setshowmoreCount] = useState(2);
  useEffect(() => {
    if (id) {
      console.log("=======================inside");
      let postData = id.split("-");
      let findType = postData[0].includes("posts");
      if (findType == true) {
        let splitPosts = postData[1].split(">").map(p => Number(p.trim()));
        let firstEle = splitPosts.shift();
        splitPosts.unshift(0);
        console.log(postManagement)
        getUniverseDetailsWithShareId(firstEle, splitPosts,true);
        getUniverseInfo(firstEle, "notshare");
        // selectedUniverse['id']=firstEle;
       
      } else {
        console.log("=======================outside");
        getSharedPostDetails();
      }
     
      
    }
    dispatch({
      type: SINGLE_TIME,
      payload: false,
    });
  }, []);
  useEffect(() => {
    if (id && !user) {
      localStorage.setItem("SharedPost",id)
      navigation("/");
      return;
    }
  }, []);

  async function getSharedPostDetails() {
    try{

      let response = await getSharePost({ token: id }, user?.accessToken);
    let postMap = response.post_track.split(",").map((item) => parseInt(item)).reverse();
    getUniverseDetailsWithShareId(response.universe_id, postMap, false);
    getUniverseInfo(response.universe_id, "notshare");
    }catch(e){
      console.log(e)
    }
  }
  async function getUniverseDetails(universeId) {
    setLoading(true);
    try {
      let content = await getOneUniverse(universeId, user.accessToken);
      if (content?.universe_data) {
        setUniverseInfo(content?.universe_data);
        setPostManagement((prev) => [
          ...prev,
          {
            level: 0,
            posts: content?.universe_data?.posts?.filter(
              (post) => post.parentId === 0
            ),
            parentId: 0,
          },
        ]);
        setOriginalPostsLevel((prev) => [
          ...prev,
          {
            level: 0,
            posts: content?.universe_data?.posts?.filter(
              (post) => post.parentId === 0
            ),
            parentId: 0,
          },
        ]);
      }
      console.log("HomeBrowse=====", postManagement)
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }
  async function getUniverseDetailsWithShareId(universeId, postMap,boolean) {
    setLoading(true);
    try {

      let content = await getOneUniverse(universeId, user.accessToken);
      console.log(content)
      if (content?.universe_data) {
        let postManageArrTemp = [

        ];
        setUniverseInfo(content?.universe_data);
        for (let i = 0; i < postMap.length; i++) {
          const objectToBeAdded = {
            level: i + 1,
            postToBeShown: content?.universe_data?.posts?.find(
              (post) => post.id === postMap[i + 1]
            ),
            posts: content?.universe_data?.posts?.filter(
              (post) => post.parentId === postMap[i]
            ),
            parentId: postMap[i],
          };
          postManageArrTemp = [...postManageArrTemp, objectToBeAdded];
          console.log("========", postManageArrTemp)
          setPostManagement(postManageArrTemp);
          setOriginalPostsLevel(postManageArrTemp);
          if(boolean == true){
            console.log(postManageArrTemp.length)
            if(postManageArrTemp.length > 2){
              console.log(postManageArrTemp.length)
              setshowpost(true)
            }
          }
          
        }
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }
  async function getUniverseInfo(id, ss) {
    try {
      const content = await getEntireUniverseInfo(
        selectedUniverse?.id || id,
        user.accessToken
      );
      setUniverseCounts(content);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    if (!selectedUniverse?.id && !id) {
      navigation("/");
      return;
    }
    if (selectedUniverse?.id && !id) {
      getUniverseDetails(selectedUniverse?.id);
    }
  }, []);
  useEffect(() => {
    if (!selectedUniverse?.id && !id) {
      navigation("/");
      return;
    }
    if (!id) {
      getUniverseInfo();
    }

  }, [updateUniverseFlag]);


  return (
    <>
      {loading ? <Loader /> : null}
      {showSidebar && (
        <Sidebar
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setLoading={setLoading}
          universeInfo={universeInfo}
          setPostManagement={setPostManagement}
          originalPostsLevel={originalPostsLevel}
          enable={enable}
          disable={disable}
          setShowSideBar={setShowSideBar}
          showSidebar={showSidebar}
          setshowpost={setshowpost}
        />
      )}
      <Header />
      <div className="HomeBrowseOverall">
        <div>
          <div className="d-flex justify-content-between HomeBrowseHeaderSection">
            <div className="col-md-6 col-8 d-flex align-items-center BrowseHeaderH5">
              <h5>Browse the universe</h5>
            </div>
            <div className="col-md-6 col-4 d-flex align-items-center justify-content-end BrowseHeaderButton">
              <button>
                <i className="fa fa-search me-md-3 me-1" aria-hidden="true"></i>
              </button>
              <button
                onClick={() => {
                  disable();
                  setShowSideBar((prevState) => !prevState);
                }}
              >
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="HomeBrowseBoxSection">
            <div className="col-12 d-flex flex-wrap HomeBrowseBox">
              <div className="col-lg-9 col-12">
                <div className="col-12 HomeBrowseBoxh6">
                  <h6>{universeCounts?.universe_title}</h6>
                </div>
                <div className="mb-lg-0  mb-1 HomeBrowseBoxPara">
                  <p>{universeCounts?.universe_description}</p>
                </div>
              </div>
              <div className="col-lg-3 col-12">
                <div className="col-12">
                  <div className="d-flex justify-content-lg-end HomeBrowseImageSection">
                    <div style={{cursor:"pointer"}} onClick={() => navigation(`/GateKeeper/${universeCounts?.owner?.id}`)} className="col-12 d-flex HomeBrowseImage">
                      <span>
                        <img src={HomeMale_img} className="me-2 cursor" />
                        {universeCounts?.owner?.username}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-lg-end">
                    <div className="me-1 HomeBrowseIconsSpan HomeBrowsethumbsup">
                      <span>
                        <i
                          className="fa fa-thumbs-up cursor"
                          aria-hidden="true"
                        ></i>
                        {universeCounts?.totalLikes || "0"}
                      </span>
                    </div>
                    <div className="me-1 HomeBrowseIconsSpan HomeBrowseuser">
                      <span>
                        <i className="fa fa-user cursor" aria-hidden="true"></i>
                        {universeCounts?.totalContributors || "0"}
                      </span>
                    </div>
                    <div className="HomeBrowseIconsSpan HomeBrowserandom">
                      <span>
                        <i
                          className="fa fa-random cursor"
                          aria-hidden="true"
                        ></i>
                        {universeCounts?.totalBranches || "0"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {console.log(postManagement)}
            {postManagement?.map((item, index) => {
              if(showpost == true){
                if(index < showmoreCount){
                  return (
                    <Branch
                      key={index + 67}
                      selectedPost={selectedPost}
                      setSelectedPost={setSelectedPost}
                      setUniverseInfo={setUniverseInfo}
                      item={item}
                      postManagement={postManagement}
                      setPostManagement={setPostManagement}
                      universeInfo={universeInfo}
                      setLoading={setLoading}
                      originalPostsLevel={originalPostsLevel}
                      setOriginalPostsLevel={setOriginalPostsLevel}
                      setUpdateUniverseFlag={setUpdateUniverseFlag}
                      updateUniverseFlag={updateUniverseFlag}
                      setshowpost={setshowpost}
                    />
                  );
                }
              }else{
                return (
                  <Branch
                    key={index + 67}
                    selectedPost={selectedPost}
                    setSelectedPost={setSelectedPost}
                    setUniverseInfo={setUniverseInfo}
                    item={item}
                    postManagement={postManagement}
                    setPostManagement={setPostManagement}
                    universeInfo={universeInfo}
                    setLoading={setLoading}
                    originalPostsLevel={originalPostsLevel}
                    setOriginalPostsLevel={setOriginalPostsLevel}
                    setUpdateUniverseFlag={setUpdateUniverseFlag}
                    updateUniverseFlag={updateUniverseFlag}
                    setshowpost={setshowpost}
                  />
                );
              }
             
              

            })}
            <div className="btn-algn">
              {console.log(showmoreCount,postManagement.length)}
              {(showpost && showmoreCount < postManagement.length) && <button className="btn" onClick={()=>{setshowmoreCount(showmoreCount+2)}}>Showmore</button>}
            </div>
            
            <div className="btn-algn">
              {console.log(showmoreCount,postManagement.length)}
              {(showpost && showmoreCount >= postManagement.length) && <button className="btn" onClick={()=>{setshowmoreCount(2)}}>back to home</button>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeBrowse;

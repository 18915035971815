import {  postData } from "./api.service";

// export const getOneUniverse = (id,token) => {
//   return fetchData(`api/getUniverseByUniverseId/${id}`, {
//     headers: { "Authorization": `Bearer ${token}` },
//   });
// };

export const updateUserInfo = (data,token) => {
  return postData('api/update-personal-info', data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateUserBillingInfo = (data,token) => {
  return postData('api/update-billing-info', data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateUserCompanyInfo = (data,token) => {
  return postData('api/update-company-info', data,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
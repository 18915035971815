import React from "react";
import styles from './Tooltip.module.css';

const showFirstletter = (value) => {
  let getFirstLetter = value[0].toUpperCase();
  return getFirstLetter
}

const replaceSlash = (value) => {
  // console.log(value);
  if (value) {
    let splitData = value.split("\\").join('/');
    return splitData
  } else {
    return ''
  }

}

const Tooltip = ({ content, handleClose }) => {
  const [show, setShow] = React.useState(false);

  return (

    <>

      <div className={styles.tooltip}>
        {/* {console.log(content)} */}
        <div className={styles.tooltiparrow}>
          {/* <span onClick={handleClose}>x</span> */}
          {content?.data?.length ? content?.data?.map((item, index) => (
            <>
              <div className={styles.photos}>
                <ul>
                  <li><span>{item?.profilePath ? (<a ><img src={`${process.env.REACT_APP_BASE_URL}/api/${replaceSlash(item?.profilePath)}`} width="36" height="36" /><span style={{paddingLeft: "5px"}}>{item.username}</span></a>):<><div style={{display:"flex",padding:"5px"}}><a className={styles.text}>{showFirstletter(item?.username)}</a><span className={styles.popuptextalign}>{item.username}</span></div></>} </span>
                    
                  </li>
                  <li>
                    <div>{content.length > 5 ? (<><span>{content.length - 5}</span></>) : null}</div>
                  </li>
                </ul>
              </div>
            </>
          )) : (<span>No Post...</span>)}
          <div>
            {content?.remainingElementsCount > 1 ? <span>{content?.remainingElementsCount} more</span>:null}
          </div>
        </div>
      </div>



    </>



    // <div>
    //   <div className="tooltip" style={show ? { visibility: "visible" } : {}}>
    //     {text}
    //     <span className="tooltip-arrow" />
    //   </div>
    //   <div
    //     {...rest}
    //     onMouseEnter={() => setShow(true)}
    //     onMouseLeave={() => setShow(false)}
    //   >
    //     {children}
    //   </div>
    // </div>
  );
}

export default Tooltip
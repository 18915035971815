import React from "react";
import "./Paid.css";

class Paid extends React.Component{
    render(){
        return(
            <>
                <div className="d-flex align-items-center justify-content-center paidOverall">                    
                        <div className="PaidSpan">
                            <span>Paid</span>
                        </div>                    
                </div>
            </>
        );
    }
}

export default Paid;
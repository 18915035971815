import React, { useEffect, useState } from "react";
import "../Pages/GateKeeper/GateKeeper.css";
import Girl_img from "../assets/images/girl.png";
import { useDispatch, useSelector } from "react-redux";
import { gateKeeperContributionsAction } from "../redux/actions/auth";
import { useNavigate, useNavigation } from "react-router";
import { getEntireUniverseInfo, getSinglePostInfo } from "../services/universe.service";

function GateKeeperContribution({ universePosts }) {
  return (
    <div className="GateKeeperContributionBoxSection">
      {universePosts.map((item) => {
        return <UniverseAndPosts item={item} />;
      })}
    </div>
  );
}

export default GateKeeperContribution;

function UniverseAndPosts({ item }) {
  const dispatch = useDispatch()
  const navigation = useNavigate()

  const user = useSelector((state) => state.auth.user);
  const [universeCounts, setUniverseCounts] = useState({});
  async function getUniverseInfo() {
    try {
      const content = await getEntireUniverseInfo(
        item.id,
        user.accessToken
      );
      setUniverseCounts(content);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
 
    getUniverseInfo();
  }, []);
  return (
    <div className="GateKeeperContributionBoxBorder">
      <div className="d-flex justify-content-center">
        <div className="GateKeeperContributionBox">
          <div className="d-flex flex-wrap mb-2">
            <div className="col-lg-7 col-12 mb-lg-0 mb-1 GateKeeperBoxh6">
              <h6>{universeCounts?.universe_title}</h6>
            </div>
            <div className="col-lg-5 col-12">
              <div className="d-flex right-align">
                <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
                  <span>
                    <i
                      className="fa fa-thumbs-up cursor"
                      aria-hidden="true"
                    ></i>
                   {universeCounts?.totalLikes}
                  </span>
                </div>
                <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
                  <span>
                    <i className="fa fa-user cursor" aria-hidden="true"></i> {universeCounts?.totalContributors}
                  </span>
                </div>
                <div className="GateKeeperBoxSpan GateKeeperrandom">
                  <span>
                    <i className="fa fa-random cursor" aria-hidden="true"></i>
                    {universeCounts?.totalBranches}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="GateKeeperBoxPara">
            <p>{universeCounts?.universe_description}</p>
          </div>
        </div>
      </div>
      {item?.posts?.slice(-2)?.map((item) => {
        return <Post postInfo={item} navigation={navigation} />;
      })}
      <div className="d-flex align-items-center justify-content-center">
        {item?.posts?.length > 2 ? <button onClick={()=>{
          dispatch(gateKeeperContributionsAction(item))
          navigation('/GateKeeper/contributions')
        }} className="ViewAllBtn">View All</button> : null}
      
      </div>
    </div>
  );
}


export function Post({ postInfo ,navigation}) {
  const [post,setPost] = useState({})
  const user = useSelector(
    (state) => state.auth.user
  );
  async function getPostDetails() {
    try {
      const response = await getSinglePostInfo(postInfo.id, user.accessToken);
      setPost(response);
    } catch (e) {
      console.log(e);
    }
  }
  React.useEffect(() => {
    getPostDetails();
  }, []);


  return (
    <div className="col-12 mb-2 d-flex flex-wrap GateKeeperBox">
      <div className="col-lg-9 col-12" onClick={() => navigation(`/HomeBrowse/posts-${post?.universe_id} > ${post?.breadCrumbs}`)}>
        <div className="d-flex align-items-center mb-md-2 mb-1">
          <div className="me-2 ContributionUniHead">
            <span>universe-1</span>
          </div>
          {/* <div className="cursor d-flex align-items-center HomeBrowseContributionsSmallDots">
            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
          </div> */}
        </div>
        <div className="mb-1 ContributionPara" >
          <p>{post.content}</p>
        </div>
      </div>
      <div className="col-lg-3 col-12">
        <div className="col-12">
          <div className="d-flex justify-content-lg-end ContributionNumber">
            <span className="cursor">#{post?.id}</span>
          </div>
          <div className="d-flex justify-content-lg-end ContributionImagePart">
            <div className="col-12 me-1 d-flex ContributionImage" style={{cursor:"pointer"}} onClick={() => navigation(`/GateKeeper/${postInfo?.author_id}`)}>
              <span>
                <img src={Girl_img} className="me-2 cursor" />
                {postInfo?.author?.user_details[0]?.name || postInfo?.author?.user_details[0]?.user.username}
              </span>
            </div>
            <div className="me-1 ContributionGuarding">
              <span>
                <i className="fa fa-clock-o cursor" aria-hidden="true"></i>
                {postInfo?.universe?.owner_id === user.id?'Gatekeeping':'Guarding'}
              </span>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-lg-end GateKeeperIconsSection">
            <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
              <span>
                <i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>
                {post?.likes?.length}
              </span>
            </div>
            <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
              <span>
                <i className="fa fa-user cursor" aria-hidden="true"></i> {post?.contributorsCount?.length}
              </span>
            </div>
            <div className="GateKeeperBoxSpan GateKeeperrandom">
              <span>
                <i className="fa fa-random cursor" aria-hidden="true"></i>
                {post?.branchCount}
              </span>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-lg-end">
            {/* <div className="me-1 GateKeeperTwoIcon">
              <i className="fa fa-heart cursor" aria-hidden="true"></i>
            </div>
            <div className="me-1 GateKeeperTwoIcon">
              <i className="fa fa-external-link cursor" aria-hidden="true"></i>
            </div>
            <div className="GateKeeperTwoIcon">
              <i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

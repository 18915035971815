import React, { useState } from "react";
import "../Pages/MyProfile/MyProfile.css";
import GateKeeperMale_img from "../assets/images/male.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getUserLikedUniversePosts } from "../services/universe.service";
import MyLikedPost from "./MyLikedPost";
import { useNavigate } from "react-router";
import { gateKeeperLikesAction } from "../redux/actions/auth";
import { MYLIKESCOUNT } from "../redux/actions/types";
import { Universe } from "./MyContributionPage";

function MyLikesPage() {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [universeWithLikes, setuniverseWIthLikes] = useState([]);
  const [universeWithLikescount, setuniverseWIthLikescount] = useState(0);
  const [selectedPost, setSelectedPost] = useState(null);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  async function getUserUniversePostsLikedByHim() {
    try {
      setLoading(true);
      let content = await getUserLikedUniversePosts(
        { userId: user.id },
        user.accessToken
      );
      if (content) {
        console.log(content);
        const initialValue = 0;
        const postLikesLength=content.map(val => val.posts.length).reduce((accumulator, currentValue) => accumulator + currentValue, initialValue)
        console.log(postLikesLength)
        dispatch({
          type: MYLIKESCOUNT,
          payload: postLikesLength,
        });
        setuniverseWIthLikes(content);
        setuniverseWIthLikescount(postLikesLength)
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }
  React.useEffect(() => {
    getUserUniversePostsLikedByHim();
  }, []);
  return (
    <div className="col-lg-9 col-12">
      <div className="col-12 d-flex align-items-center justify-content-between AddNewUniverseSection">
        <div className="d-flex align-items-center">
          <div className="Universesh6">
            <h6>My Likes</h6>
          </div>

          <div className="UniversesA">
            <a >{universeWithLikescount}</a>
          </div>
        </div>
      </div>
      <div className="col-12 MyProfileRight">
        
        {universeWithLikes.length > 0 &&
          universeWithLikes.map((item) => {
            return (
              <div className="GateKeeperContributionBoxSection">
                <div className="GateKeeperContributionBoxBorder">
                <Universe item={item?.universe} />

                  {item?.posts?.slice(-2)?.map((element, index) => {
                
                    return (
                      <MyLikedPost
                        item={item?.universe}
                        selectedPost={element}
                        navigation={navigation}
                      />
                    );
                  })}

                  <div className="d-flex align-items-center justify-content-center">
                    {item?.posts?.length > 2 ? (
                      <button
                        onClick={() => {
                          dispatch(gateKeeperLikesAction(item));
                          navigation("/GateKeeper/likes");
                        }}
                        className="ViewAllBtn"
                      >
                        View All
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default MyLikesPage;

import React from "react";

function Loader() {
  return (
    <div
      className="position-fixed d-flex justify-content-center align-items-center"
      style={{
        top: 0,
        left: 0,
        height: "100vh",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        zIndex: 9999,
      }}
    >
      <span className="spinner-border spinner-border-lg text-white"></span>
    </div>
  );
}

export default Loader;

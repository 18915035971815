import { MYCONTRIBUTIONCOUNT, MYLIKESCOUNT } from "../actions/types";

const initialState = {
    mylikes:null
};



export default function (state = initialState, action) {
  const { type, payload } = action;
   console.log(payload)
  switch (type) {
    case MYLIKESCOUNT:
      return { mylikes: payload };

    default:
      return state;
  }
}

import axios from "axios";
import authHeader from "./auth-header";
import { getUniverseByUniverseId,getUniverseByUserId } from "../redux/actions/user_details/user_details";
import { BASE_URL } from "../config/config";

const API_URL = `${BASE_URL}/api/`;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }
  getUsers() {
    return axios.get(API_URL + "getusers", { headers: authHeader() });
  }
  

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }
  getUserDetails(user_id) {
    return axios.post(API_URL + "getuserdetails", { user_id },{ headers: authHeader() }).then((response) => {
      if (response.data) {
        localStorage.setItem("user_data", JSON.stringify(response.data?.user_data));
      }

      return response.data;
    })
    .catch((error)=>{
      console.log(error)
    });
  }
  

  addNewUniverse(universeData) {
    return axios.post(API_URL + "addNewUniverse", universeData,{ headers: authHeader() }).then((response) => {
      if (response.data) {
        // localStorage.setItem("universesData", JSON.stringify(response.data?.universes_data));
      }

      return response.data;
    })
    .catch((error)=>{
      console.log(error)
      // localStorage.setItem("user_data", JSON.stringify(error.data?.user_data));
    });
  }

  addNewPost(universeData) {
    return axios.post(API_URL + "addNewPost", universeData,{ headers: authHeader() }).then((response) => {
      if (response.data) {
        //localStorage.setItem("universesData", JSON.stringify(response.data?.universes_data));
      }

      return response.data;
    })
    .catch((error)=>{
      console.log(error)
      // localStorage.setItem("user_data", JSON.stringify(error.data?.user_data));
    });
  }

 


getUniverseByUserId(id) {
  return axios.get(API_URL + "getUniverseByUserId/"+id, { headers: authHeader() }).then((response) => {
  

    return response.data;
  })
  .catch((error)=>{
    console.log(error)
    // localStorage.setItem("user_data", JSON.stringify(error.data?.user_data));
  });
}

getContributionsByUserId(id) {
  return axios.get(API_URL + "getContributionsByUserId/"+id, { headers: authHeader() }).then((response) => {
    if (response.data) {
      localStorage.setItem("selectedUserContributionData", JSON.stringify(response.data?.universe_data));
    }

    return response.data;
  })
  .catch((error)=>{
    console.log(error)
    // localStorage.setItem("user_data", JSON.stringify(error.data?.user_data));
  });
}

}



export default new UserService();

import React from "react";
import "./PrivateTab.css";
import PrivateTab_img from "../../assets/images/myprofileimg.png";
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header'
// class PrivateTab extends React.Component {
//     render() {
                 
function  PrivateTab() {
    const navigate = useNavigate();

const MyUniverseTwo=()=>{
    navigate('/MyUniverseTwo');
}


        return (
            <>
                <Header/>
                <div className="PrivateTabOverall">
                    <div>
                        <div className="col-12 d-flex flex-wrap GateKeeperImgSection">
                            <div className="col-md-7 col-12 mb-md-0 mb-1">
                                <div className="col-12 d-flex">
                                    <div className="GateKeeperImage">
                                        <img src={PrivateTab_img} />
                                    </div>
                                    <div>
                                        <div className="GateKeeperImgSectionName">
                                            <h5>Jane Doe</h5>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center justify-content-center cursor GateKeeperBlockIcon">
                                                <i className="fa fa-user-times" aria-hidden="true"></i>
                                            </div>
                                            <div className="GateKeeperBlockSpan">
                                                <span>Block or Report user</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 mb-md-0 mb-1 d-flex justify-content-center">
                                <div className="GateKeeperImgSectionIcons">
                                    <ul>
                                        <li><a href="#" className="d-flex align-items-center"><i className="fa fa-building d-flex align-items-center justify-content-center" aria-hidden="true"></i> Name of the company</a></li>
                                        <li><a href="#" className="d-flex align-items-center"><i className="fa fa-briefcase d-flex align-items-center justify-content-center" aria-hidden="true"></i> 24 participants</a></li>
                                        <li><a href="#" className="d-flex align-items-center"><i className="fa fa-map-marker d-flex align-items-center justify-content-center" aria-hidden="true"></i> Location</a></li>
                                        <li className="GateKeeperImgSectionIconsBG"><a href="#" className="d-flex align-items-center"><i className="fa fa-link d-flex align-items-center justify-content-center" aria-hidden="true"></i> Website</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 GateKeeperUniversesSection">
                            <div>
                                <ul className="nav nav-pills d-flex justify-content-between" id="pills-tab" role="tablist">
                                    <li className="nav-item active col-md-3 col-6 mb-md-0 mb-2" role="presentation">
                                        <button className="nav-link active d-flex PrivateTabButton" id="pills-one-tab" data-bs-toggle="pill" data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one" aria-selected="true">
                                            <div className="PrivateTabA PrivateTabAbg">
                                                <a href="#">32</a>
                                            </div>
                                            My Profile
                                        </button>
                                    </li>
                                    <li className="nav-item col-md-3 col-6 mb-md-0 mb-2 d-flex justify-content-md-start justify-content-end" role="presentation">
                                        <button className="nav-link d-flex PrivateTabButton" id="pills-two-tab" data-bs-toggle="pill" data-bs-target="#pills-two" type="button" role="tab" aria-controls="pills-two" aria-selected="false">
                                            <div className="PrivateTabA">
                                                <a href="#">256</a>
                                            </div>
                                            My Universes
                                        </button>
                                    </li>
                                    <li className="nav-item col-md-3 col-6" role="presentation">
                                        <button className="nav-link d-flex PrivateTabButton" id="pills-three-tab" data-bs-toggle="pill" data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three" aria-selected="false">
                                            <div className="PrivateTabA">
                                                <a href="#">256</a>
                                            </div>
                                            My Contributions
                                        </button>
                                    </li>
                                    <li className="nav-item col-md-3 col-6 d-flex justify-content-end" role="presentation">
                                        <button className="nav-link d-flex PrivateTabButton" id="pills-four-tab" data-bs-toggle="pill" data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four" aria-selected="false">
                                            <div className="PrivateTabA">
                                                <a href="#">12K</a>
                                            </div>
                                            My Likes
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab">
                                    <div className="GateKeeperBoxSection">
                                        <div className="GateKeeperBoxBorder">
                                            <div className="GateKeeperBoxSectionName">
                                                <h6>Private</h6>
                                            </div>
                                            <div className="GateKeeperBoxSectionSubName">
                                                <span>List of private Universes</span>
                                            </div>
                                            <div className="mb-2 GateKeeperBox">
                                                <div className="d-flex flex-wrap mb-2">
                                                    <div className="col-lg-8 col-12 mb-lg-0 mb-1 GateKeeperBoxh6">
                                                        <h6>Universe Title (a little more test to make it a realistic title)</h6>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="d-flex">
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
                                                                <span><i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>2.8K</span>
                                                            </div>
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
                                                                <span><i className="fa fa-user cursor" aria-hidden="true"></i>8</span>
                                                            </div>
                                                            <div className="GateKeeperBoxSpan GateKeeperrandom">
                                                                <span><i className="fa fa-random cursor" aria-hidden="true"></i>108</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="GateKeeperBoxPara">
                                                    <p>Description about the universe.some more text explaining the universe.so a lot to read about it.</p>
                                                </div>
                                            </div>
                                            <div className="GateKeeperBox">
                                                <div className="d-flex flex-wrap mb-2">
                                                    <div className="col-lg-8 col-12 mb-lg-0 mb-1 GateKeeperBoxh6">
                                                        <h6>Universe Title (a little more test to make it a realistic title)</h6>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="d-flex">
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
                                                                <span><i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>2.8K</span>
                                                            </div>
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
                                                                <span><i className="fa fa-user cursor" aria-hidden="true"></i>8</span>
                                                            </div>
                                                            <div className="GateKeeperBoxSpan GateKeeperrandom">
                                                                <span><i className="fa fa-random cursor" aria-hidden="true"></i>108</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="GateKeeperBoxPara">
                                                    <p>Description about the universe.some more text explaining the universe.so a lot to read about it.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="GateKeeperBoxTwo">
                                            <div className="GateKeeperBoxSectionName">
                                                <h6>Public</h6>
                                            </div>
                                            <div className="GateKeeperBoxSectionSubName">
                                                <span>List of public Universes</span>
                                            </div>
                                            <div className="mb-2 GateKeeperBox">
                                                <div className="d-flex flex-wrap mb-2">
                                                    <div className="col-lg-8 col-12 mb-lg-0 mb-1 GateKeeperBoxh6">
                                                        <h6>Universe Title (a little more test to make it a realistic title)</h6>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="d-flex">
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
                                                                <span><i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>2.8K</span>
                                                            </div>
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
                                                                <span><i className="fa fa-user cursor" aria-hidden="true"></i>8</span>
                                                            </div>
                                                            <div className="GateKeeperBoxSpan GateKeeperrandom">
                                                                <span><i className="fa fa-random cursor" aria-hidden="true"></i>108</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="GateKeeperBoxPara">
                                                    <p>Description about the universe.some more text explaining the universe.so a lot to read about it.</p>
                                                </div>
                                            </div>
                                            <div className="GateKeeperBox">
                                                <div className="d-flex flex-wrap mb-2">
                                                    <div className="col-lg-8 col-12 mb-lg-0 mb-1 GateKeeperBoxh6">
                                                        <h6>Universe Title (a little more test to make it a realistic title)</h6>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="d-flex">
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
                                                                <span><i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>2.8K</span>
                                                            </div>
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
                                                                <span><i className="fa fa-user cursor" aria-hidden="true"></i>8</span>
                                                            </div>
                                                            <div className="GateKeeperBoxSpan GateKeeperrandom">
                                                                <span><i className="fa fa-random cursor" aria-hidden="true"></i>108</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="GateKeeperBoxPara">
                                                    <p>Description about the universe.some more text explaining the universe.so a lot to read about it.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab">
                                    <div className="GateKeeperBoxSection">
                                        <div className="GateKeeperBoxBorder">
                                            <div className="GateKeeperBoxSectionName">
                                                <h6>Private</h6>
                                            </div>
                                            <div className="GateKeeperBoxSectionSubName">
                                                <span>List of private Universes</span>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="col-md-8 col-11 d-flex PrivateTabTimeUpgradeSection">
                                                    <div className="col-1 position-relative PrivateTabTimeUpgradeLogo">
                                                        <i className="fa fa-bolt position-absolute" aria-hidden="true"></i>
                                                    </div>
                                                    <div>
                                                        <div className="PrivateTabTimeUpgradeh5">
                                                            <h5>Time to Upgrade</h5>
                                                        </div>
                                                        <div className="PrivateTabTimeUpgradeP">
                                                            <p>
                                                                Some message about upgrading to the paid package to have accesss to paid features as well as private universes.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center PrivateTabupgradenowspan">
                                                <span onClick={MyUniverseTwo}>Upgrade Now</span>
                                            </div>
                                        </div>
                                        <div className="GateKeeperBoxTwo">
                                            <div className="GateKeeperBoxSectionName">
                                                <h6>Public</h6>
                                            </div>
                                            <div className="GateKeeperBoxSectionSubName">
                                                <span>List of public Universes</span>
                                            </div>
                                            <div className="mb-2 GateKeeperBox">
                                                <div className="d-flex flex-wrap mb-2">
                                                    <div className="col-lg-8 col-12 mb-lg-0 mb-1 GateKeeperBoxh6">
                                                        <h6>Universe Title (a little more test to make it a realistic title)</h6>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="d-flex">
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
                                                                <span><i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>2.8K</span>
                                                            </div>
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
                                                                <span><i className="fa fa-user cursor" aria-hidden="true"></i>8</span>
                                                            </div>
                                                            <div className="GateKeeperBoxSpan GateKeeperrandom">
                                                                <span><i className="fa fa-random cursor" aria-hidden="true"></i>108</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="GateKeeperBoxPara">
                                                    <p>Description about the universe.some more text explaining the universe.so a lot to read about it.</p>
                                                </div>
                                            </div>
                                            <div className="GateKeeperBox">
                                                <div className="d-flex flex-wrap mb-2">
                                                    <div className="col-lg-8 col-12 mb-lg-0 mb-1 GateKeeperBoxh6">
                                                        <h6>Universe Title (a little more test to make it a realistic title)</h6>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="d-flex">
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperthumbsup">
                                                                <span><i className="fa fa-thumbs-up cursor" aria-hidden="true"></i>2.8K</span>
                                                            </div>
                                                            <div className="me-1 GateKeeperBoxSpan GateKeeperuser">
                                                                <span><i className="fa fa-user cursor" aria-hidden="true"></i>8</span>
                                                            </div>
                                                            <div className="GateKeeperBoxSpan GateKeeperrandom">
                                                                <span><i className="fa fa-random cursor" aria-hidden="true"></i>108</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="GateKeeperBoxPara">
                                                    <p>Description about the universe.some more text explaining the universe.so a lot to read about it.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab">..3.</div>
                                <div className="tab-pane fade" id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab">..4.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
// }

export default PrivateTab;
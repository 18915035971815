import React from "react";
import "./Register.css";
import login_image from "../../assets/images/login.png";
import { NavLink, useNavigate } from "react-router-dom";
import { isEmail } from "validator";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { connect } from "react-redux";
import { register } from "../../redux/actions/auth";
import { history } from "../../helpers/history";
import authService from "../../services/auth.service";
import { verifyOTP } from "../../services/authentication.service";
import { toast } from "react-toastify";
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vphone = (value) => {
  if (value.length < 10 || value.length > 10) {
    return (
      <div className="alert alert-danger" role="alert">
        Phone Number must be 10 digit
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};
class Register extends React.Component {
  constructor(props) {
    super(props);
    // const navigate = useNavigate();
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
    this.onChangeOTP = this.onChangeOTP.bind(this);
    this.state = {
      username: "",
      email: "",
      mobilenumber: "",
      password: "",
      successful: false,
      emailexist: false,
      showOtpField: false,
      otp: "",
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }
  onChangeOTP(e) {
    this.setState({
      otp: e.target.value,
    });
  }

  async onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });

    const checkEmail = await authService
      .emailExist(e.target.value)
      .then((val) => {
        return val.data.status;
      });
    console.log(checkEmail);
    if (isEmail(e.target.value)) {
      if (checkEmail) {
        this.setState({
          emailexist: true,
        });
      }
    } else {
      this.setState({
        emailexist: false,
      });
    }
  }
  onChangePhone(e) {
    this.setState({
      mobilenumber: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  goToLogin() {
    history.push("/login");
    window.location.reload();
  }

  async handleRegister(e) {
    e.preventDefault();

    if (!this.state.showOtpField) {
      this.setState({
        successful: false,
      });

      this.form.validateAll();
      if (this.checkBtn.context._errors.length === 0) {
        this.props
          .dispatch(
            register(
              this.state.username,
              this.state.email,
              this.state.mobilenumber,
              this.state.password
            )
          )
          .then(() => {
            this.setState({ showOtpField: true });
            this.setState({
              successful: true,
            });
          })
          .catch(() => {
            this.setState({
              successful: false,
            });
          });
      }

      return false;
    }
    if (this.state.showOtpField) {
      try {
        if (!this.state.otp?.trim()) {
          this.setState({ successful: false, message: "PLease enter OTP" });
          return false;
        }
        const response = await verifyOTP({
          email: this.state.email,
          otp: this.state.otp,
        });
        this.setState({
          successful: true,
          message: "Registration successful, please login",
        });
        toast.success("Registration successful, please login")

        history.push("/login");
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
      return false;
    }
  }

  login = () => {
    history.push("/login");
  };
  onChangeEmail;

  RegisterData = () => {
    history.push("/");
  };

  // function Register() {

  render() {
    const { message } = this.props;
    return (
      <>
        <div className="overallwrapper">
          <div className="d-flex flex-wrap loginInnerWrapper">
            <div className="col-6 d-lg-flex d-none align-items-center justify-content-center position-relative loginleft">
              <div className="loginEllipse"></div>
              <div className="position-absolute loginImg">
                <img src={login_image} />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="d-flex justify-content-center logincont">
                <div>
                  <h5>Register account</h5>
                </div>
                <Form
                  onSubmit={this.handleRegister}
                  ref={(c) => {
                    this.form = c;
                  }}
                >
                  {!this.state.showOtpField && (
                    <div>
                      <div className="logincontlabel">
                        <label htmlFor="username">Username</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="username"
                          value={this.state.username}
                          onChange={this.onChangeUsername}
                          validations={[required, vusername]}
                        />
                      </div>

                      <div className="logincontlabel">
                        <label htmlFor="email">Email</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="email"
                          value={this.state.email}
                          onChange={this.onChangeEmail}
                          validations={[required, email]}
                        />
                        {this.state.emailexist && (
                          <>
                            <div className="alert alert-danger" role="alert">
                              Email Already exist.
                            </div>
                          </>
                        )}
                      </div>

                      <div className="logincontlabel pwinput">
                        <label htmlFor="password">Password</label>
                        <Input
                          type="password"
                          className="form-control"
                          name="password"
                          value={this.state.password}
                          onChange={this.onChangePassword}
                          validations={[required, vpassword]}
                        />
                      </div>

                      <div className="form-group">
                        <button className="btn btn-primary btn-block">
                          Sign Up
                        </button>
                      </div>
                    </div>
                  )}
                  {this.state.showOtpField && (
                    <div>
                      <div className="logincontlabel">
                        <label htmlFor="username">Enter OTP</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="otp"
                          value={this.state.otp}
                          onChange={this.onChangeOTP}
                        />
                      </div>
                      <div className="form-group">
                        <button className="btn btn-primary btn-block">
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="registeralert">
                    {message && (
                      <div className="form-group">
                        <div
                          className={
                            this.state.successful
                              ? "alert alert-success"
                              : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {message}
                        </div>
                      </div>
                    )}
                  </div>
                  <CheckButton
                    style={{ display: "none" }}
                    ref={(c) => {
                      this.checkBtn = c;
                    }}
                  />
                </Form>
                <div className="text-center loginRegister">
                  <span>Already have an account?</span>{" "}
                  <NavLink className="register" to="/login">
                    {" "}
                    Login!
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(Register);
